import { FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { CardFromMUI } from "../../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import HeaderSubSectionInForm from "../../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import { interfaceApiGetInformationTariffs, interfaceCarInformation } from "./interfaceCarInformation";
import EditTextHorizontal from "../../../../../components/editTextHorizontal/EditTextHorizontal";
import colors from "../../../../../config/colors";
import { ReactNode, useEffect, useState } from "react";
import { LabelInputFromMUI } from "../../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../../../components/MUIConfig/SelectFromMUI";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ArrowLeftRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import links from "../../../../../config/links";
interface interfaceSelector { id: number; title: string; };

function CarInformation(componentsParams: interfaceCarInformation) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getApiGetInformationTariffs, setApiGetInformationTariffs] = useState<interfaceApiGetInformationTariffs>();
    const [getListOfCarCompany, setListOfCarCompany] = useState<interfaceSelector[]>([]);
    const [getSelectedOfCarCompany, setSelectedOfCarCompany] = useState<number>(-1);
    const [getListOfCarModel, setListOfCarModel] = useState<interfaceSelector[]>([]);
    const [getSelectedOfCarModel, setSelectedOfCarModel] = useState<number>(-1);
    const [getListOfCarYear, setListOfCarYear] = useState<interfaceSelector[]>([]);
    const [getSelectedOfCarYear, setSelectedOfCarYear] = useState<number>(-1);
    const [getCarVIN, setCarVIN] = useState<string>("");
    const apiCallGetListAllTariffs = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.contract.getCompanyTariff;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceApiGetInformationTariffs;
                setApiGetInformationTariffs(t);
                // create list of company
                var uniqueCompany = [] as interfaceSelector[];
                t.data.map((tarf, index, array) => {
                    return ({
                        "id": tarf.car_type.brand.id,
                        "title": tarf.car_type.brand.title
                    })
                }).forEach((company, index) => {
                    if (uniqueCompany.filter(newCom => newCom.id == company.id).length == 0)
                        uniqueCompany.push(company)
                });
                console.log("uniqueCompany::::", uniqueCompany);
                setListOfCarCompany(uniqueCompany);
                // create list of model
                var uniqueModel = [] as interfaceSelector[];
                t.data.map((tarf, index, array) => {
                    return ({
                        "id": tarf.car_type.id,
                        "title": tarf.car_type.title
                    })
                }).forEach((model, index) => {
                    if (uniqueModel.filter(newCom => newCom.id == model.id).length == 0)
                        uniqueModel.push(model)
                })
                setListOfCarModel(uniqueModel);
                // create list of year
                var uniqueYear = [] as interfaceSelector[];
                t.data.map((tarf, index, array) => {
                    return ({
                        "id": tarf.year.id,
                        "title": tarf.year.title
                    })
                }).forEach((year, index) => {
                    if (uniqueYear.filter(newCom => newCom.id == year.id).length == 0)
                        uniqueYear.push(year)
                })
                setListOfCarYear(uniqueYear);


                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const apiCallForCreateContract = async () => {
        if (getApiGetInformationTariffs?.data.filter(f => f.year.id == getSelectedOfCarYear
            && f.car_type.id == getSelectedOfCarModel
            && f.car_type.brand.id == getSelectedOfCarCompany
        ).length == 0) {
            dispatch(ShowAlert({ text: "همچین مدلی در سیستم تعریف نشده است", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            setSelectedOfCarModel(-1)
            setSelectedOfCarYear(-1)
            return;
        }
        var urlCall = urlsList.panel.contract.postCreateContract;
        dispatch(isVisibilityProgressBar(true));
        const ObjSend = {
            "car_tariff": getApiGetInformationTariffs?.data.filter(f => f.year.id == getSelectedOfCarYear
                && f.car_type.id == getSelectedOfCarModel
                && f.car_type.brand.id == getSelectedOfCarCompany
            )[0].id,
            "car_vin": getCarVIN,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, ObjSend, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                navigate(links.panel.request.createRequest + `/${response.data.id}`)
                // componentsParams.callbackGetInfoAgain();
                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        apiCallGetListAllTariffs();
    }, [])
    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <HeaderSubSectionInForm
                            text={"اطلاعات خودرو"} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: colors.black.black,
                                width: "100%",
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{"برند"}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit",
                                    background: colors.yellow.light,
                                    borderRadius: "555px",


                                }}
                                value={getSelectedOfCarCompany}
                                label={'برند'}
                                onChange={(event: SelectChangeEvent<unknown>, child: ReactNode) => {
                                    setSelectedOfCarCompany(Number(event.target.value))
                                }}
                            >
                                {getListOfCarCompany.map((brand, index, arr) => {

                                    return (
                                        <MenuItemFromMUI value={brand.id}
                                            key={brand.id}>{brand.title}</MenuItemFromMUI>
                                    )

                                })

                                }
                            </SelectFromMUI>
                        </FormControl>

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: colors.black.black,
                                width: "100%",
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{"تیپ"}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit",
                                    background: colors.yellow.light,
                                    borderRadius: "555px",


                                }}
                                value={getSelectedOfCarModel}
                                label={'تیپ'}
                                onChange={(event: SelectChangeEvent<unknown>, child: ReactNode) => {
                                    setSelectedOfCarModel(Number(event.target.value))
                                }}
                            >

                                {getListOfCarModel
                                    .filter((model) => {
                                        return getApiGetInformationTariffs?.data.filter(tar => (tar.car_type.brand.id == getSelectedOfCarCompany || getSelectedOfCarCompany == -1) && tar.car_type.id == model.id).length ?? 0 > 0
                                    })
                                    .map((model, index, arr) => {

                                        return (
                                            <MenuItemFromMUI value={model.id}
                                                key={model.id}>{model.title}</MenuItemFromMUI>
                                        )

                                    })

                                }

                            </SelectFromMUI>
                        </FormControl>

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: colors.black.black,
                                width: "100%",
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{"سال ساخت"}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit",
                                    background: colors.yellow.light,
                                    borderRadius: "555px",


                                }}
                                value={getSelectedOfCarYear}
                                label={'سال ساخت'}
                                onChange={(event: SelectChangeEvent<unknown>, child: ReactNode) => {
                                    setSelectedOfCarYear(Number(event.target.value))
                                }}
                            >

                                {getListOfCarYear
                                    .filter((year) => {
                                        return getApiGetInformationTariffs?.data.filter(tar =>
                                            (tar.car_type.brand.id == getSelectedOfCarCompany || getSelectedOfCarCompany == -1)
                                            && (tar.car_type.id == getSelectedOfCarModel || getSelectedOfCarModel == -1)
                                            && (tar.year.id == year.id)).length ?? 0 > 0
                                    })
                                    .map((year, index, arr) => {

                                        return (
                                            <MenuItemFromMUI value={year.id}
                                                key={year.id}>{year.title}</MenuItemFromMUI>
                                        )

                                    })

                                }

                            </SelectFromMUI>
                        </FormControl>

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"شماره (VIN)"}
                            value={getCarVIN}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setCarVIN} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <ButtonEffectShadow text={"ثبت"}
                                colors={colors.panel.buttonBackgroundGreen}
                                iconEnded={<ArrowLeftRounded />}
                                onclick={apiCallForCreateContract} />

                        </div>
                    </Grid>
                </GridFromMUI>
            </div>

        </CardFromMUI >
    )
}
export default CarInformation;