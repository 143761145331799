import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import links from "../config/links";

function HomePage() {
    return (

        <div style={{ width: "100vw", height: "100vh", background: "dark", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "4vh" }}>


            <Link to={links.loginAndRegister.register}>
                <Button>
                    login
                </Button>
            </Link>
            <Link to={links.panel.account.accountManagment}>
                <Button>
                    manage account
                </Button>
            </Link>
        </div>
    )
}
export default HomePage;