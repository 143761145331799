import colors from "../../config/colors";
import { CardFromMUI } from "../MUIConfig/CardFromMUI";
import { interfaceHeaderSubSectionInForm } from "./interfaceHeaderSubSectionInForm";

function HeaderSubSectionInForm(componentsParams: interfaceHeaderSubSectionInForm) {
    return (
        <CardFromMUI style={{
            width: "fit-content", borderInlineStart: "0px", borderBlock: "0px",
            paddingInlineEnd: "8px", boxSizing: "border-box"
        }}>
            <div style={{
                display: "flex", flexDirection: "row", alignItems: "stretch",
                gap: "calc(1vw + 8px)", justifyContent: "start"
            }}>
                <div style={{ background: colors.green.active, width: "4px" }} />
                <p style={{ fontWeight: "600" }}>
                    {componentsParams.text}
                </p>
            </div>
        </CardFromMUI>
    )
}
export default HeaderSubSectionInForm;