import { FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { CardFromMUI } from "../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { interfaceAccountInformation, interfaceGetApiListAllTypeOfCompany } from "./interfaceAccountInformation";
import colors from "../../../../config/colors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import EditText from "../../../../components/editText/EditText";
import EditTextHorizontal from "../../../../components/editTextHorizontal/EditTextHorizontal";
import DateTimePickHorizentalMe from "../../../../components/dateTimePickHorizental/DateTimePickHorizentalMe";
import HeaderSubSectionInForm from "../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ReactNode, useEffect, useState } from "react";
import { EnumStateAcceptCompany } from "../../../../utils/StateAcceptCompany";
import moment from "moment";
import { LabelInputFromMUI } from "../../../../components/MUIConfig/LabelInputFromMUI";
import { SelectFromMUI } from "../../../../components/MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../../../components/MUIConfig/MenuItemFromMUI";
import { EnumGender } from "../../../../utils/Gender";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { interfaceApiGetInformationAccount } from "../accountManagment/interfaceAccountManagment";
import { CheckRounded, AddRounded } from "@mui/icons-material";
import FilesDragAndDrop from "../../../../components/FileDragAndDrop/FileDragAndDrop";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";


function AccountInformation(pagesParams: interfaceAccountInformation) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getTypeOfCompany, setTypeOfCompany] = useState<number | undefined>(undefined);
    const [getCompanysName, setCompanysName] = useState<string>();
    const [getCompanysNationalId, setCompanysNationalId] = useState<string>();
    const [getCompanysEconomyCode, setCompanysEconomyCode] = useState<string>();
    const [getCompanysRegistrationCode, setCompanysRegistrationCode] = useState<string>();
    const [getCompanysDateRegistration, setCompanysDateRegistration] = useState<number>();
    const [getCompanysNameCEO, setCompanysNameCEO] = useState<string>();
    const [getCEONationalCode, setCEONationalCode] = useState<string>();
    const [getCEOBirthDay, setCEOBirthDay] = useState<number>();
    const [getCEOPhone, setCEOPhone] = useState<string>();
    const [getLanline, setLanline] = useState<string>();
    const [getEmail, setEmail] = useState<string>();
    const [getPostCode, setPostCode] = useState<string>();
    const [getAddress, setAddress] = useState<string>();

    const [gettrading_card_img, settrading_card_img] = useState<File>();
    const [gettrading_card_imgURL, settrading_card_imgURL] = useState<string>();
    const [getceo_national_card_img, setceo_national_card_img] = useState<File>();
    const [getceo_national_card_imgURL, setceo_national_card_imgURL] = useState<string>();
    const [getcompany_registration_img, setcompany_registration_img] = useState<File>();
    const [getcompany_registration_imgURL, setcompany_registration_imgURL] = useState<string>();

    const [getListAllTypeOfCompany, setListAllTypeOfCompany] = useState<interfaceGetApiListAllTypeOfCompany>({ data: [] });
    useEffect(() => {
        setTypeOfCompany(pagesParams.ServerGetInfoAtFirstTime?.data.company_type);
        setCompanysName(pagesParams.ServerGetInfoAtFirstTime?.data.company_name);
        setCompanysNationalId(pagesParams.ServerGetInfoAtFirstTime?.data.company_id);
        setCompanysEconomyCode(pagesParams.ServerGetInfoAtFirstTime?.data.financial_code);
        setCompanysRegistrationCode(pagesParams.ServerGetInfoAtFirstTime?.data.reg_number);
        setCompanysDateRegistration(pagesParams.ServerGetInfoAtFirstTime?.data.reg_date);
        setCompanysNameCEO(pagesParams.ServerGetInfoAtFirstTime?.data.ceo_name);
        setCEONationalCode(pagesParams.ServerGetInfoAtFirstTime?.data.ceo_national_id);
        setCEOBirthDay(pagesParams.ServerGetInfoAtFirstTime?.data.ceo_dob);
        setCEOPhone(pagesParams.ServerGetInfoAtFirstTime?.data.ceo_phone);
        setLanline(pagesParams.ServerGetInfoAtFirstTime?.data.landline);
        setEmail(pagesParams.ServerGetInfoAtFirstTime?.data.email);
        setPostCode(pagesParams.ServerGetInfoAtFirstTime?.data.postal_code);
        setAddress(pagesParams.ServerGetInfoAtFirstTime?.data.address);

        settrading_card_imgURL(pagesParams.ServerGetInfoAtFirstTime?.data.trading_card_img);
        setceo_national_card_imgURL(pagesParams.ServerGetInfoAtFirstTime?.data.ceo_national_card_img);
        setcompany_registration_imgURL(pagesParams.ServerGetInfoAtFirstTime?.data.company_registration_img);
    }, [pagesParams.ServerGetInfoAtFirstTime]);
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        setTypeOfCompany(Number(event.target.value))
    }
    const getApiListAllTypeOfCompany = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.getCompanyType;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, {}, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetApiListAllTypeOfCompany;
                console.log(t)
                setListAllTypeOfCompany(t)

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const patchApiCompanyInforamtion = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patchAccountCompany;
        const formData = new FormData();
        formData.append("company_name", String(getCompanysName))
        formData.append("company_id", String(getCompanysNationalId))
        formData.append("ceo_name", String(getCompanysNameCEO))
        formData.append("company_type", String(getTypeOfCompany))
        formData.append("financial_code", String(getCompanysEconomyCode))
        formData.append("reg_number", String(getCompanysRegistrationCode))
        formData.append("reg_date", String(getCompanysDateRegistration))
        formData.append("ceo_national_id", String(getCEONationalCode))
        formData.append("ceo_dob", String(getCEOBirthDay))
        formData.append("ceo_phone", String(getCEOPhone))
        formData.append("landline", String(getLanline))
        formData.append("email", String(getEmail))
        formData.append("national_id", String(getCEONationalCode))
        formData.append("address", String(getAddress))
        formData.append("postal_code", String(getPostCode))
        if (gettrading_card_img != undefined) {
            formData.append("trading_card_img", gettrading_card_img)
        }
        if (getceo_national_card_img != undefined) {
            formData.append("ceo_national_card_img", getceo_national_card_img)
        }
        if (getcompany_registration_img != undefined) {
            formData.append("company_registration_img", getcompany_registration_img)
        }


        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, true)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as interface;
                console.log(response)
                // setListAllTypeOfCompany(t)

                dispatch(ShowAlert({ text: "اطلاعات ثبت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                pagesParams.callbackGetInfoAgain();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (gettrading_card_img) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                settrading_card_imgURL(event.target.result as string);
            };

            reader.readAsDataURL(gettrading_card_img);
        }
    }, [gettrading_card_img]);
    useEffect(() => {
        if (getceo_national_card_img) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setceo_national_card_imgURL(event.target.result as string);
            };

            reader.readAsDataURL(getceo_national_card_img);
        }
    }, [getceo_national_card_img]);
    useEffect(() => {
        if (getcompany_registration_img) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setcompany_registration_imgURL(event.target.result as string);
            };

            reader.readAsDataURL(getcompany_registration_img);
        }
    }, [getcompany_registration_img]);
    useEffect(() => {
        console.log("safajooon")
        getApiListAllTypeOfCompany();
    }, []);

    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 6 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <HeaderSubSectionInForm
                            text={"اطلاعات شرکت‌ خود را کامل کنید"} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <FormControl
                            style={{
                                margin: "0px 0px 0px 0px",
                                color: colors.black.black,
                                width: "100%"
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{"نوع شرکت‌"}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: colors.black.black,
                                    background: colors.yellow.light,
                                    borderRadius: "8px",

                                }}
                                value={`${getTypeOfCompany}`}
                                label={"نوع شرکت‌"}
                                onChange={handleChangeDropDown}
                            >
                                {getListAllTypeOfCompany.data.map((value, index, array) => {
                                    return (
                                        <MenuItemFromMUI value={value.id}
                                            key={value.id}>{value.title}</MenuItemFromMUI>
                                    )
                                })}
                            </SelectFromMUI>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"نام شرکت‌"}
                            value={getCompanysName}

                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setCompanysName} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"شناسه ملی"}
                            value={getCompanysNationalId}
                            onChangeValue={setCompanysNationalId}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                        />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"کد اقتصادی"}
                            value={getCompanysEconomyCode}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setCompanysEconomyCode} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"شماره ثبت"}
                            value={getCompanysRegistrationCode}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setCompanysRegistrationCode} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <DateTimePickHorizentalMe
                            title={"تاریخ ثبت"}
                            styleMain={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            valueSecound={getCompanysDateRegistration ? (moment.unix(getCompanysDateRegistration).unix() * 1000) : undefined}
                            onChange={function (newDate: any) {
                                if (newDate) {
                                    const posixTimestamp = newDate.getTime(); // Get milliseconds since Unix epoch
                                    setCompanysDateRegistration(posixTimestamp / 1000);
                                } else {
                                    setCompanysDateRegistration(undefined);
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"مدیر عامل"}
                            value={getCompanysNameCEO}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setCompanysNameCEO} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"کدملی"}
                            value={getCEONationalCode}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}
                            type="number"
                            isSmallHintShow={false}
                            onChangeValue={setCEONationalCode} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <DateTimePickHorizentalMe
                            title={"تاریخ تولد"}
                            styleMain={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            valueSecound={getCEOBirthDay ? (moment.unix(getCEOBirthDay).unix() * 1000) : undefined}
                            onChange={function (newDate: any) {
                                if (newDate) {
                                    const posixTimestamp = newDate.getTime(); // Get milliseconds since Unix epoch
                                    setCEOBirthDay(posixTimestamp / 1000);
                                } else {
                                    setCEOBirthDay(undefined);
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"موبایل مدیرعامل"}
                            value={getCEOPhone}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}
                            type="number"
                            isSmallHintShow={false}
                            onChangeValue={setCEOPhone} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"تلفن ثابت"}
                            value={getLanline}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            type="number"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setLanline} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"ایمیل"}
                            value={getEmail}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}
                            type="email"
                            isSmallHintShow={false}
                            onChangeValue={setEmail} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"کدپستی"}
                            value={getPostCode}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}
                            type="number"
                            isSmallHintShow={false}
                            onChangeValue={setPostCode} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <EditTextHorizontal
                            title={"آدرس پستی"}
                            value={getAddress}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={setAddress} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: 'start', justifyContent: "start", gap: "calc(1vw + 12px)", flexWrap: "wrap" }}>
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            settrading_card_img(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={gettrading_card_imgURL ? undefined : AddRounded} color="white"
                                            isScaleable={false}
                                            img={gettrading_card_imgURL}
                                            background={colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />

                                        <p style={{ textAlign: "center" }}>{"عکس بازرگانی"}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>

                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setceo_national_card_img(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getceo_national_card_imgURL ? undefined : AddRounded} color="white"
                                            isScaleable={false}
                                            img={getceo_national_card_imgURL}
                                            background={colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p style={{ textAlign: "center" }}>{"کارت‌ ملی مدیرعامل"}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setcompany_registration_img(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getcompany_registration_imgURL ? undefined : AddRounded} color="white"
                                            isScaleable={false}
                                            img={getcompany_registration_imgURL}
                                            background={colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p style={{ textAlign: "center" }}>{"عکس روزنامه رسمی"}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={6}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: 'row',
                            justifyContent: "end", alignItems: "center"
                        }}>
                            <ButtonEffectShadow text={"به روزرسانی"}
                                colors={colors.panel.buttonBackgroundGreen}
                                onclick={patchApiCompanyInforamtion}
                            />
                        </div>
                    </Grid>

                </GridFromMUI>

            </div>
        </CardFromMUI>
    )
}
export default AccountInformation;