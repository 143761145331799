import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
export const baseUrl = "https://chista-api.niktech.org"
// Put your new endpoint like here.
export const HandlerLangUrl = (url: string, mtheme: any) => {
    return (`${baseUrl}${mtheme.direction == "rtl" ? "/fa" : "/en"}${url}`)
}
export interface interfaceUrl {
    url: string;
    method: SupportedMethods;
    isTokenNecessary: boolean;
    isFileInclude: boolean;

}
export const urlsList = {

    auth: {
        generateOTP: { url: `/account/auth/generate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        validateOTP: { url: `/account/auth/validate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        refreshToken: { url: `/account/auth/refresh/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },

    },
    panel: {
        getInformationOfAccount: { url: `/account/user/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        patchAccountCompany: { url: `/account/user/info/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: true },
        getCompanyType: { url: `/account/user/company-type/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        getAccountCompanyInfo: { url: `/account/user/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        contract: {
            postCreateContract: { url: `/contract/contract/new/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            getCompanyTariff: { url: `/contract/tariff/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getCarBrands: { url: `/contract/tariff/brands/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getInformationTheContract: { url: `/contract/contract/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            patchInformationTheContract: { url: `/contract/contract/info/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            getListContracts: { url: `/contract/contract/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        }
    }
};