import Card from "@mui/material/Card/Card";
import { styled as styledMUI } from "@mui/material/styles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Box, Button, LinearProgress, useTheme } from "@mui/material";
import EditText from "../../../components/editText/EditText";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import { useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { InterfaceRegisterValidateOTP } from "./interfaceRegisterGroup";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { useDispatch } from "react-redux";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { DivAllTextContainer } from "./RegisterGetPhoneOrEmail";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";


const ImgLogo = styledMUI("img")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('md')]: {
        width: "50vw",
    },
    [theme.breakpoints.up('md')]: {
        width: "300px",
    }
}));


const RegisterValidateOTP = (paramsPage: InterfaceRegisterValidateOTP) => {
    const navigate = useNavigate();
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const [getValueEditText, setValueEditText] = useState<string>("");
    const dispatch = useDispatch();

    const onClickSubmitHandler = async () => {
        dispatch(isVisibilityProgressBar(true));
        const dataValidationOTP = {
            phone: paramsPage.phoneNumber,
            otp: getValueEditText,
        };
        var objectRequest = urlsList.auth.validateOTP;

        await RequestHandler(dispatch, HandlerLangUrl(objectRequest.url, mtheme),
            objectRequest.method, objectRequest.isTokenNecessary, dataValidationOTP)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "ورود با موفقیت", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                paramsPage.callbackForUpdateState(getValueEditText, response.data.access, response.data.refresh);
            })
            .catch((e: any) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    return (
        <DivAllTextContainer style={{ display: paramsPage.display ? "flex" : "none" }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={33} />
            </Box>
            <div style={{
                marginTop: "4vh",
                marginBottom: "4vh",
                marginLeft: "6vw",
                marginRight: "6vw",
                paddingTop: "calc(48px + 2vh)",
                display: "flex",
                flexDirection: "column",
                gap: "calc(2vh + 12px)",
                alignItems: "center"
            }}>



                <EditText title={confComps.panel.loginAndRegister.register.txtEditText.otp.title}
                    styledTextField={{
                        border: "1px solid #fff",
                        borderRadius: "555px",
                        width: "100%",
                        color: colors.panel.doctor.account.step.edittext.color,
                        background: colors.panel.doctor.account.step.edittext.background,
                    }}
                    isDataMustLtr={true}
                    hintText={confComps.panel.loginAndRegister.register.txtEditText.otp.hintText}
                    sizeWidth="small"
                    onChangeValue={setValueEditText}
                    isNecessary={false}
                    isMultiLine={false}
                    isSmallTitle={true}
                    isColorDark={false}
                    isSmallHintShow={false} />
                <ButtonEffectShadow text={confComps.panel.loginAndRegister.register.acceptOTP}
                    onclick={onClickSubmitHandler} styleMainDiv={{ width: "100%" }} />
            </div>
        </DivAllTextContainer>



    )
}

export default RegisterValidateOTP;


