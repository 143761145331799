import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { CardFromMUI } from "../../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import HeaderSubSectionInForm from "../../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import { interfaceInquiryLatter } from "./interfaceInquiryLatter";
import { useState } from "react";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import { AddRounded, ArrowLeftRounded, CheckRounded } from "@mui/icons-material";
import EditTextHorizontal from "../../../../../components/editTextHorizontal/EditTextHorizontal";
import DateTimePickHorizentalMe from "../../../../../components/dateTimePickHorizental/DateTimePickHorizentalMe";
import FilesDragAndDrop from "../../../../../components/FileDragAndDrop/FileDragAndDrop";
import {ImageSquare} from "../../../../../components/imageSquare/ImageSquare";

function InquiryLatter(componentsParams: interfaceInquiryLatter) {
    const [getLetterFile, setLetterFile] = useState<(File)>();
    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <HeaderSubSectionInForm
                            text={"استعلام نامه گمرک"} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <EditTextHorizontal
                            title={"شماره نامه استعلام گمرک"}
                            // value={getAccountnationalId}
                            styledTextField={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isColorDark={true}

                            isSmallHintShow={false}
                            onChangeValue={function (value: string) {
                                throw new Error("Function not implemented.");
                            }} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <DateTimePickHorizentalMe
                            title={"تاریخ نامه"}
                            styleMain={{
                                width: "100%",
                                color: "black",
                                background: colors.yellow.light,
                            }}
                            onChange={function (value: any) {
                                throw new Error("Function not implemented.");
                            }}
                        />

                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <div>

                            <FilesDragAndDrop
                                onUpload={(files) => {
                                    console.log(files)
                                    files.forEach((value) => {
                                        setLetterFile(value);
                                    })
                                }}
                                count={1}
                                formats={['jpg', 'png', 'pdf']}
                                containerStyles={{
                                    borderRadius: "4px",
                                    boxSizing: "border-box",

                                }}
                                openDialogOnClick
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    gap: "calc(1vh + 12px)",
                                    alignItems: "center",
                                    // width: "fit-content",
                                    boxSizing: "border-box",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    background: colors.yellow.light,
                                    border: "1px solid black", paddingInlineEnd: "8px"
                                }}>
                                    <ImageSquare
                                        iconSvg={getLetterFile ? CheckRounded : AddRounded}
                                        // color="white"
                                        background={getLetterFile ? colors.green.active : "linear-gradient(0deg, rgb(200, 200, 206) 0%, rgb(235, 235, 235) 100%)"}
                                        width={"62px"} borderRadius="0px"
                                    />
                                    <p style={{ maxLines: "1" }}>{getLetterFile ? getLetterFile.name : "اسکن نامه"}</p>

                                </div>
                            </FilesDragAndDrop>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <ButtonEffectShadow
                                text={"مرحله بعد"}
                                colors={colors.panel.buttonBackgroundGreen}
                                onclick={() => {
                                    componentsParams.callbackGetInfoAgain()
                                }}
                                iconEnded={<ArrowLeftRounded />}
                            />

                        </div>
                    </Grid>


                </GridFromMUI>
            </div>
        </CardFromMUI>
    )
}
export default InquiryLatter;