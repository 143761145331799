import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import colors from "../../../../config/colors";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceAccountManagment, interfaceApiGetInformationAccount } from "./interfaceAccountManagment";
import { createTheme, styled as styledMUI, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import AccountInformation from "../accountInformation/AccountInformation";
import AccountStatus from "../accountStatus/AccountStatus";
import AcceptedAccount from "../acceptedAccount/AcceptedAccount";
import { EnumStateAcceptCompany } from "../../../../utils/StateAcceptCompany";
enum EnumStateOfTitle {
    deactive = "de",
    active = "ac",
    waiting = "wa",
}
const DivLevel = styledMUI("div", { shouldForwardProp: (prop) => prop !== 'active', })<{ active?: EnumStateOfTitle; }>(({
    theme,
    active
}) => ({
    cursor: active != EnumStateOfTitle.deactive ? "pointer" : undefined,
    width: "33.34%",
    textAlign: "center",
    borderBottom: `3px solid ${active == EnumStateOfTitle.active ? colors.panel.doctor.account.title.active : active == EnumStateOfTitle.deactive ? colors.panel.doctor.account.title.deactive : colors.panel.doctor.account.title.waiting}`,
    color: `${active == EnumStateOfTitle.active ? colors.panel.doctor.account.title.active : active == EnumStateOfTitle.deactive ? colors.panel.doctor.account.title.deactive : colors.panel.doctor.account.title.waiting}`
}));
function AccountManagement(pagesParams: interfaceAccountManagment) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountInformation, setAccountInformation] = useState<interfaceApiGetInformationAccount>();
    const [getStepDisplay, setStepDisplay] = useState<number>(-1);

    const getInformationOfAccountAtFirstTime = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.getInformationOfAccount;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, {}, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetInformationAccount;

                setAccountInformation(t)
                if (t.data.status == EnumStateAcceptCompany.EMPTY) {
                    setStepDisplay(0)
                }
                else if (t.data.status == EnumStateAcceptCompany.PENDING) {
                    setStepDisplay(1)
                }
                else if (t.data.status == EnumStateAcceptCompany.APPROVED) {
                    setStepDisplay(2)
                }
                else if (t.data.status == EnumStateAcceptCompany.REJECTED) {
                    setStepDisplay(2)
                }
                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getInformationOfAccountAtFirstTime();
    }, []);
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                <DivLevel active={getStepDisplay > 0 ? EnumStateOfTitle.active : EnumStateOfTitle.waiting}
                    onClick={() => {
                        // if ((getAccountFirstTime?.data.status ?? EnumStateAcceptCompany.EMPTY) >= 0) {
                        // setStepDisplay(0)
                        // }
                    }}>
                    {"تکمیل اطلاعات"}
                </DivLevel>
                <DivLevel active={getStepDisplay == 0 ? EnumStateOfTitle.deactive : getStepDisplay == 1 ? EnumStateOfTitle.waiting : EnumStateOfTitle.active}
                    onClick={() => {
                        // var strTemp = (getAccountFirstTime?.data.status ?? EnumStateAcceptCompany.EMPTY)
                        // if ((getAccountFirstTime?.data.status ?? EnumStateAcceptCompany.EMPTY) >= 1) {
                        // setStepDisplay(1)
                        // }
                    }}>
                    {"در انتظار تایید"}
                </DivLevel>
                <DivLevel active={((getAccountInformation?.data.status ?? EnumStateAcceptCompany.EMPTY).includes(EnumStateAcceptCompany.APPROVED)) ? EnumStateOfTitle.active : EnumStateOfTitle.deactive}
                    onClick={() => {
                        // var strTemp = (getAccountFirstTime?.data.status ?? EnumStateAcceptCompany.EMPTY)
                        // if (strTemp.includes(EnumStateAcceptCompany.APPROVED)) {
                        // setStepDisplay(3)
                        // }
                    }}>
                    {"تایید حساب"}
                </DivLevel>
            </div>
            {getStepDisplay == 0 ? <AccountInformation
                typeOfDisplay={pagesParams.typeOfDisplay}
                ServerGetInfoAtFirstTime={getAccountInformation}
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
            /> : undefined}
            {getStepDisplay == 1 ? <AccountStatus
                typeOfDisplay={pagesParams.typeOfDisplay}

                ServerGetInfoAtFirstTime={getAccountInformation}
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
            /> : undefined}
            {getStepDisplay == 2 ? <AcceptedAccount
                typeOfDisplay={pagesParams.typeOfDisplay}

                ServerGetInfoAtFirstTime={getAccountInformation}
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
            /> : undefined}
        </div>
    )
}
export default AccountManagement;
