import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { createTheme, styled as styledMUI, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import colors from './config/colors';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { InterfaceSection } from './components/AppBarMenu/navbarMain/InterfaceNavbarMainPage';
import { enumListSizeDevices } from './config/responsive/configResponsive';
import { SelectedAlert } from './store/AlertStateModeSlice';
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { selectedTheme, ThemeStructure } from './store/themeModeSlice';
import { NavbarMainPage } from './components/AppBarMenu/navbarMain/NavbarMainPage';
import { ConfigComps } from './config/ConfigCOMP';
import { HelperResponsive } from './helper/Helper';
import { Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import links from './config/links';
import LoginPage from './screens/login_and_register/login/LoginPage';
import RegisterPage from './screens/login_and_register/register/RegisterPage';
import HomePage from './screens/HomePage';
import AccountManagement from './screens/panel/accout/accountManagment/AccountManagment';
import RequestsList from './screens/panel/myRequest/requestsList/RequestsList';
import CreateRequest from './screens/panel/myRequest/createRequest/CreateRequest';
import PrivateRedirectToLogin from './api/PrivateRedirectToLogin';
const SpaceUnderMenuSide = styledMUI("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "330px",
  [theme.breakpoints.down('md')]: {
    display: "none",
  },
  [theme.breakpoints.up('md')]: {
    display: "block",
  }
}));
const RemindingSpace = styledMUI("div")(({ theme }) => ({
  background: "#fafafa",
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
  width: "100vw",
  minHeight: "100vh",
  boxSizing: "border-box",
  padding: "64px 4vw 0 4vw",

  [theme.breakpoints.up('md')]: {
    padding: "48px 2vw 0 2vw",
    width: "calc(100% - 330px)"
  }
}));

function App() {
  const themeInStore = useSelector(selectedTheme);
  const alertStateInStore = useSelector(SelectedAlert);
  const progressbarInstore = useSelector(SelectedProgressbarInRoot);
  const { t, i18n } = useTranslation();

  const mainThem = createTheme({
    ...((themeInStore as any) as ThemeStructure).theme
  });
  const [displayType, SetDisplayType] = useState<enumListSizeDevices>(0);
  const mTheme = useTheme();
  const setLangugeFromStore = async () => {
    i18n.changeLanguage(mainThem.direction == "rtl" ? "fa" : "en");
  }
  const dispatch = useDispatch();
  const AddSideMenu = (element: any, TypeOfDisplay: enumListSizeDevices, sections: InterfaceSection[]) => {
    return (
      <div>
        <NavbarMainPage

          typeOfDisplay={TypeOfDisplay} sections={sections} />
        <div style={{
          width: "100%", height: "100%", display: "flex", flexDirection: "row", boxSizing: 'border-box',
          paddingTop: TypeOfDisplay < enumListSizeDevices.tablet ? "calc(1vh + 16px)" : "8px",
        }}>
          <SpaceUnderMenuSide />
          <RemindingSpace>

            {element}

          </RemindingSpace>
        </div>
      </div >

    );

  }
  useEffect(() => {
    // i18n.changeLanguage(mTheme.direction == "rtl" ? "en" : "fa");
    // dispatch(SetDirectionOfTheme(mTheme.direction == "rtl" ? "ltr" : "rtl"));
    SetDisplayType(HelperResponsive());
    setLangugeFromStore();
  }, []);
  var confComps = ConfigComps();
  return (
    <ThemeProvider theme={mainThem}>
      <div dir={mainThem.direction} style={{ direction: mainThem.direction, height: "100vh" }}>
        <AlertPopup typeOfWarning={alertStateInStore.alerts.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.alerts.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
          text={alertStateInStore.alerts.text} lastTimeecondsUpdate={alertStateInStore.alerts.lastTimeecondsUpdate} />
        <ProgressBarInRoots isVisible={progressbarInstore.isVisibility} value={progressbarInstore.value} lastTimeMilisUpdate={progressbarInstore.lastTimeMilisUpdate} />
        <BrowserRouter>
          <Routes>
            <Route path={links.loginAndRegister.register} element={<RegisterPage />} />
            <Route path='/panel' element={<PrivateRedirectToLogin />}>
              <Route path={links.panel.account.accountManagment} element={AddSideMenu(<AccountManagement typeOfDisplay={displayType} />, displayType, confComps.panel.sideBar.sections)} />
              <Route path={links.panel.request.requestList} element={AddSideMenu(<RequestsList typeOfDisplay={displayType} />, displayType, confComps.panel.sideBar.sections)} />
              <Route path={links.panel.request.createRequest} element={AddSideMenu(<CreateRequest typeOfDisplay={displayType} />, displayType, confComps.panel.sideBar.sections)} />
              <Route path={links.panel.request.createRequest+"/:idContract/"} element={AddSideMenu(<CreateRequest typeOfDisplay={displayType} />, displayType, confComps.panel.sideBar.sections)} />

            </Route>

            <Route path={links.home} element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
