//
// import  {} from '@reduxjs/toolkit'
import { configureStore } from "@reduxjs/toolkit";
import { themeModeSlice } from "./themeModeSlice";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { profileStateSlice } from "./profileStateSlice";
import { alertStateModeSlice } from "./AlertStateModeSlice";
import { ProgressbarInRootSlice } from "./ProgressbarInRootSlice";


const reducers = combineReducers({
    alerts: alertStateModeSlice.reducer,
    theme: themeModeSlice.reducer,
    profiles: profileStateSlice.reducer,
    progressbarInRoot: ProgressbarInRootSlice.reducer,
    
});
//
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],// all item in this list will not be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;
