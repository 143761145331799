import { SvgIconComponent } from "@mui/icons-material";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";

export interface InterfaceNavbarMainPage {
    typeOfDisplay: enumListSizeDevices;
    sections: InterfaceSection[]
}
export interface InterfaceSection {
    title: string;
    subtitles: InterfaceSubtitle[];
}
export interface InterfaceSubtitle {
    link: string;
    text: string;
    icon:SvgIconComponent;
}
export enum enumLanguage {
    en = "en",
    fa = "fa"
};
