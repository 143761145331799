const links = {
    logo: "/img/login_background.png",

    panel: {
        account: {
            accountManagment: "/panel/account",
        },
        request: {
            createRequest: "/panel/requests/create",
            requestList: "/panel/requests",
        }
    },
    loginAndRegister: {
        login: "/login",
        register: "/register",
    },

    home: "/",



}
export default links;