import Card from "@mui/material/Card/Card";
import { styled as styledMUI } from "@mui/material/styles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Box, Button, FormControl, LinearProgress, SelectChangeEvent } from "@mui/material";
import EditText from "../../../components/editText/EditText";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import links from "../../../config/links";
import { useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { InterfaceRegisterGetPhoneOrEmail } from "./interfaceRegisterGroup";
import { ReactNode, useState } from "react";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { useTheme } from "@emotion/react";
import { AlertPopup } from "../../../components/MUIConfig/alert_popup/AlertPopup";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";


export const DivAllTextContainer = styledMUI(Card)(({ theme }) => ({
    color: "white",
    background: "rgba(217,214,214,0.15)",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    zIndex: 100,
    position: "absolute",
    top: "50%",


    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        maxWidth: "80%",
        minWidth: "50%",
        transform: "translate(0,-50%)",
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "center",
        maxWidth: "40%",
        minWidth: "30%",
        right: theme.direction == "ltr" ? undefined : 0,
        left: theme.direction == "ltr" ? 0 : undefined,
        transform: theme.direction == "ltr" ? "translate(12.5%,-50%)" : "translate(-12.5%,-50%)",
    }

}));
const ImgLogo = styledMUI("img")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('md')]: {
        width: "50vw",
    },
    [theme.breakpoints.up('md')]: {
        width: "300px",
    }
}));


const RegisterGetPhoneOrEmail = (paramsPage: InterfaceRegisterGetPhoneOrEmail) => {
    const navigate = useNavigate();
    const mtheme = useTheme();
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    const [getValueEditText, setValueEditText] = useState<string>("");

    const onClickSubmitHandler = async () => {

        dispatch(isVisibilityProgressBar(true));

        const dataLogin = {
            phone: getValueEditText,
        };
        var urlSelected = urlsList.auth.generateOTP;

        await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mtheme),
            urlSelected.method, urlSelected.isTokenNecessary, dataLogin)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(ShowAlert({ text: "کد برای شما پیامک شد.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                paramsPage.callbackForUpdateState(getValueEditText)
                // dispatch(SetTokenAndRefreshToken({ token: response.data.access, refreshToken: response.data.refresh, lastUpdatePosixTime: Date.now() }))

            })
            .catch((e: any) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    try {
                        console.log("omad toooo try", JSON.stringify(e))
                        if (JSON.stringify(e).toString().includes("Kavenegar")) {
                            console.log("omad toooo")
                            paramsPage.callbackForUpdateState(getValueEditText);
                            dispatch(ShowAlert({ text: `شما شارژ ندارید فعلا عبور کنید:)`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                        }
                    }
                    catch (error: any) {
                        dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                    }
                }
            });


    }



    return (
        <DivAllTextContainer style={{ display: paramsPage.display ? "flex" : "none" }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={0} />
            </Box>
            <div style={{
                marginLeft: "6vw",
                marginRight: "6vw",
                boxSizing: "border-box",
                gap: "calc(1vh + 12px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "calc(48px + 2vh)",
            }}>

                <EditText title={confComps.panel.loginAndRegister.register.txtEditText.phone.title}
                    styledTextField={{
                        border: "1px solid #fff",
                        borderRadius: "555px",
                        width: "100%",
                        color: colors.panel.doctor.account.step.edittext.color,
                        background: colors.panel.doctor.account.step.edittext.background,
                    }}
                    isDataMustLtr={true}
                    hintText={confComps.panel.loginAndRegister.register.txtEditText.phone.hintText}
                    sizeWidth="small"
                    onChangeValue={setValueEditText}
                    isNecessary={false}
                    isMultiLine={false}
                    isSmallTitle={true}
                    isColorDark={false}
                    isSmallHintShow={false} />
                <div style={{
                    flexGrow: "1", display: "flex", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: "end", width: "100%",
                }}>

                    <ButtonEffectShadow text={confComps.panel.loginAndRegister.register.acceptPhone}
                        styleMainDiv={{ width: "100%" }}
                        onclick={onClickSubmitHandler} />
                </div>
                <div style={{ height: "calc(1vh + 12px)" }} />
                {/* <TextWithLink list={confComps.panel.loginAndRegister.register.textRedirect} /> */}
            </div>
        </DivAllTextContainer>



    )
}

export default RegisterGetPhoneOrEmail;