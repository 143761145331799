import * as React from 'react';
import { styled as styledMUI, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import colors from "../../../config/colors";
import { Button, Drawer, Icon, IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
    AccountBalanceWalletRounded, AccountBalanceWalletSharp,
    AddRounded,
    CancelOutlined,
    CancelRounded,
    Diversity1Rounded,
    ForumRounded,
    ForumSharp,
    HomeRounded,
    MinimizeRounded,
    PowerSettingsNewRounded,
    RemoveRounded
} from "@mui/icons-material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import links from "../../../config/links";
import { Link } from "react-router-dom";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { useEffect, useState } from "react";
import { HelperResponsive } from "../../../helper/Helper";
import InterfaceSideBarProductDetails from './InterfaceSideBarMain';
import { ConfigComps } from '../../../config/ConfigCOMP';
import InterfaceSideBarMain from './InterfaceSideBarMain';
import { SetTokenAndRefreshToken } from '../../../store/profileStateSlice';
import { useDispatch } from 'react-redux';





const styleOfText: CSSProperties = {
    textAlign: "start",
    textDecoration: "none"
}
const styleOfIcon: CSSProperties = {
    justifyContent: "center"
}
const CustomDrawer = styledMUI(Drawer)(({ theme }) => ({
    "& .MuiPaper-elevation": {
        borderEndEndRadius: "10px",

        [theme.breakpoints.up('md')]: {
            zIndex: 999,
            boxShadow: theme.direction == "rtl" ?
                "-2px 2px 1px -1px rgb(0 0 0 / 20%), -1px 1px 1px 0px rgb(0 0 0 / 14%), -1px 1px 3px 0px rgb(0 0 0 / 12%)" :
                " 1px 2px 1px -1px rgb(0 0 0 / 20%),  1px 1px 1px 0px rgb(0 0 0 / 14%),  1px 1px 3px 0px rgb(0 0 0 / 12%)",
            height: "-webkit-fill-available",
        }
    },
    "& .MuiPaper-root": {
        left: theme.direction == "rtl" ? "unset" : "0",
        right: theme.direction == "rtl" ? "0" : "unset",
    }


}));

const LogoContainer = styledMUI("div")(({ theme }) => ({
    fontsize: "32px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    marginBlockStart: "4px",
    marginInlineEnd: "calc(4px + 1vw)",
}));
const TitleSection = styledMUI("h4")(({ theme }) => ({
    fontSize: "16px",
    color: colors.Navbar.backgroundColor,
    fontWeight: "bold",
    margin: "45px 0 10px",
    lineHeight: "22px",
}));
const RowOfSection = styledMUI("p")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
    padding: "4px 0"
}));
const TitleOfRowOfSection = styledMUI("span")(({ theme }) => ({
    lineHeight: "26px",
    flexGrow: "1",
    fontSize: "16px",
}));
const ExplainTextOfSection = styledMUI("p")(({ theme }) => ({
    fontSize: "18px",
    lineHeight: "26px",
    marginBottom: "15px",
}));
const UnitOfRowOfSection = styledMUI("span")(({ theme }) => ({
    lineHeight: "26px",
    width: "100px",
    fontSize: "12px",

}));
const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: "0",
    left: "0",
    pointerEvents: "none",
    background: "blue",
    width: "120%",
    height: "101%",
    zIndex: "-1",
    transformOrigin: "0% 100%",
    transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
    transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const ButtonSelected = styledMUI(Button, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean; }>(({
    theme,
    isSelected
}) => {
    return ({
        width: "100%",
        pointerEvents: "auto",
        borderRadius: "2em",
        padding: "0.5rem 3rem",
        marginBottom: "18px",
        textAlign: "center",
        border: "1px solid",
        fontSize: "14px",
        overflow: "hidden",
        position: "relative",
        zIndex: "2",
        '&:hover': {
            color: colors.Navbar.color,
            'div': {
                position: "absolute",
                top: "0",
                left: "0",
                width: "120%",
                height: "100%",
                zIndex: "-1",
                transform: "rotate(0deg) translate3d(0, 0em, 0)",
            }
        }
    })
});
const ButtonProducts = styledMUI(Button)(({ theme }) => ({
    width: "100%",
    color: colors.Navbar.backgroundColor,
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    marginBottom: "18px",
    textAlign: "center",
    border: "1px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    '&:hover': {
        color: colors.Navbar.color,
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: colors.Navbar.backgroundColor,
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));
const CustomListItemButton = styled(ListItemButton)((theme) => ({
    borderRadius: "555px",
    textAlign: "right",
    width: "100%",
    "&.Mui-selected, &.Mui-selected:hover": {
        color: colors.hamburgerMenu.textSelected,
        backgroundColor: colors.hamburgerMenu.ButtonSelected
    }


}));


function SideBarMain(paramsComponent: InterfaceSideBarMain) {
    const mTheme = useTheme();
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    const handleToGoExit = () => {
        dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }))
        window.location.href = links.home;
    };
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (paramsComponent.callBackOnClose) {
            paramsComponent.callBackOnClose();
        }
    }


    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    return (
        <CustomDrawer

            variant={paramsComponent.typeOfDisplay > enumListSizeDevices.tablet ? "permanent" : "temporary"}
            //from which side the drawer slides in
            anchor={mTheme.direction == "rtl" ? "left" : "left"}
            //if open is true --> drawer is shown
            open={paramsComponent.typeOfDisplay <= enumListSizeDevices.tablet ?
                paramsComponent.isHamburgerMenuShow : true}
            //function that is called when the drawer should close
            onClose={toggleDrawer(false)}
            style={{
                // paddingTop: (paramsComponent.isHamburgerMenuStaticAndFixPosition) ? "40px" : undefined,
                direction: mTheme.direction
            }}
            dir={mTheme.direction}
        //function that is called when the drawer should open
        // onOpen={toggleDrawer(true)}
        >
            {/* The inside of the drawer */}
            <div
                style={{
                    width: paramsComponent.typeOfDisplay > enumListSizeDevices.tablet ? "330px" : undefined,
                    height: "100vh"
                }}
            >
                <div style={{ padding: "0 40px", overflow: "hidden", height: "100vh", position: "relative" }}>

                    <div style={{
                        position: "sticky", top: "0", "width": "100%", padding: "5px 0 0", background: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "end", width: "100%" }}>
                            {paramsComponent.typeOfDisplay <= enumListSizeDevices.tablet ?
                                <IconButton onClick={() => {
                                    if (paramsComponent.callBackOnClose)
                                        paramsComponent.callBackOnClose()
                                }}>
                                    <CancelOutlined

                                        style={{
                                            color: "red",
                                            cursor: "pointer",
                                            width: "32px", height: "32px",
                                            fontSize: "27px",
                                            transition: ".2s all ease"
                                        }} />
                                </IconButton>
                                :
                                <div style={{ height: "2vh" }} />
                            }

                        </div>
                        <img src={`${links.logo}`} width="50%" />
                        <div style={{
                            width: "100%",
                            display: "block",
                            position: "relative",
                            height: "20px",
                            top: "20px",
                            backgroundImage: "linear-gradient(white, rgba(255, 255, 255, 0))",
                        }} />


                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", overflow: "auto" }}>
                        {
                            paramsComponent.sections.map((section, index, array) => {
                                return (
                                    <div key={(index + 1) * 10} style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "start", justifyContent: "space-between", gap: "8px" }}>
                                        <h4>
                                            {section.title}
                                        </h4>
                                        {
                                            section.subtitles.map((subtitle, indexSub, arraySub) => {
                                                return (


                                                    <Link to={subtitle.link}
                                                        key={(indexSub + 1) * 100}
                                                        style={{
                                                            width: "100%",
                                                            textDecoration: "none",
                                                            color: isLocationContainLink(subtitle.link) ?
                                                                colors.hamburgerMenu.textSelected : colors.hamburgerMenu.iconColor
                                                        }}>
                                                        <CustomListItemButton
                                                            selected={isLocationContainLink(subtitle.link)}>
                                                            <ListItemIcon style={styleOfIcon}>
                                                                {<subtitle.icon
                                                                    fontSize={"large"} sx={{
                                                                        color: isLocationContainLink(subtitle.link) ?
                                                                            colors.hamburgerMenu.textSelected : colors.hamburgerMenu.iconColor
                                                                    }}
                                                                />}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subtitle.text} style={styleOfText} />


                                                        </CustomListItemButton>
                                                    </Link>
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{
                        position: "absolute", bottom: "0", "width": "100%",
                        left: "0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        padding: "0 40px"
                        ,
                    }}
                    >

                        <div style={{
                            width: "100%",
                            display: "block",
                            position: "relative",
                            height: "20px",
                            top: "0px",
                            backgroundImage: "linear-gradient(rgba(255, 255, 255, 0),white)",
                        }} />
                        <div style={{
                            background: "white",
                            paddingBottom: "40px",
                            display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "end", width: "100%"
                        }}>

                            <CustomListItemButton
                                onClick={handleToGoExit}
                            >
                                <ListItemIcon style={styleOfIcon}>
                                    <PowerSettingsNewRounded sx={{ color: colors.hamburgerMenu.iconExit }} />
                                </ListItemIcon>
                                <ListItemText primary={confComps.panel.appBar.sideBar.exit} style={styleOfText} />

                            </CustomListItemButton>


                        </div>





                    </div>
                </div>


            </div>

        </CustomDrawer>
    );
}


export default SideBarMain;