import { styled as styledMUI } from "@mui/material/styles";
import links from "../../../config/links";
import { useNavigate } from "react-router-dom";
import RegisterGetPhoneOrEmail from "./RegisterGetPhoneOrEmail";
import Box from "@mui/system/Box/Box";
import { useState } from "react";
import RegisterValidateOTP from "./RegisterValidateOTP";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { AlertPopup } from "../../../components/MUIConfig/alert_popup/AlertPopup";
import { useDispatch } from "react-redux";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";




const ImageBackGround = styledMUI("img")(({ theme }) => ({
    objectFit: "cover",
    position: "absolute",
    alignItems: "center",
    top: "50%",
    transform: "translate(0, -50%)",

    right: theme.direction == "rtl" ? undefined : 0,
    left: theme.direction == "rtl" ? 0 : undefined,
    zIndex: 0,
    maxHeight: "100vh",
    minWidth: "50%",
    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        width: "100%",
        height: "100%",
    }
}));

const BackgroundGradientLeft = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "rgba(37, 50, 91, 1)",
        width: "100%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "rtl" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(90deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "51%",
    }


}));


const BackgroundGradientRight = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    background: "#FF0000",
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "linear-gradient(270deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "70%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "ltr" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(270deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "51%",
    }
}));


function RegisterPage() {
    const [getLevelOfRegisteration, setLevelOfRegisteration] = useState<number>(0);
    const [getPhoneOrEmail, setPhoneOrEmail] = useState<string>("");
    const [getOTP, setOTP] = useState<string>("");
    const [getToken, setToken] = useState<string>("");
    const [getRefreshToken, setRefreshToken] = useState<string>("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const callbackForUpdateStateFromPhoneOrEmail = (phoneOrEmail: string) => {
        setPhoneOrEmail(phoneOrEmail);
        setLevelOfRegisteration(1);
    }
    const callbackForUpdateStateFromValidationOTP = (otp: string, token: string, refreshToken: string) => {
        setOTP(otp);
        setLevelOfRegisteration(2);
        setToken(token);
        setRefreshToken(refreshToken);
        dispatch(SetTokenAndRefreshToken({ token: token, refreshToken: refreshToken, lastUpdatePosixTime: Date.now() }));
        navigate(links.panel.account.accountManagment);
    }
    const callbackForUpdateStateFromSetPassword = (password: string) => {
        setPhoneOrEmail(password);
        setLevelOfRegisteration(3);
        // navigate(links.panel.processes_me.listProcess);
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: "green",
            backgroundRepeat: "no-repeat",
            position: "relative"
        }}>

            <ImageBackGround
                src="/img/login_background.png"
                srcSet="/img/login_background.png"
                alt="not loaded"
                loading="lazy"
            />
            <BackgroundGradientLeft />
            {/* <BackgroundGradientRight /> */}



            <RegisterGetPhoneOrEmail display={getLevelOfRegisteration == 0 ? true : false} callbackForUpdateState={callbackForUpdateStateFromPhoneOrEmail} />
            <RegisterValidateOTP display={getLevelOfRegisteration == 1 ? true : false} phoneNumber={getPhoneOrEmail} callbackForUpdateState={callbackForUpdateStateFromValidationOTP} />



        </div>
    )
}

export default RegisterPage;