import { Box, Checkbox, FormControlLabel, Grid, Rating, Typography, useTheme } from "@mui/material";
import { CardFromMUI } from "../../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import HeaderSubSectionInForm from "../../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import { interfaceCustomsInquiryResult } from "./interfaceCustomsInquiryResult";
import { useState } from "react";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import { ArrowLeftRounded, CancelRounded, DoneRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import links from "../../../../../config/links";
import StarIcon from '@mui/icons-material/Star';

function CustomsInquiryResult(componentsParams: interfaceCustomsInquiryResult) {
    const [getValueOfRating, setValueOfRating] = useState<number | null>();
    const [hover, setHover] = useState(-1);
    const navigate = useNavigate();
    const mTheme = useTheme();
    var confComps = ConfigComps();


    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <HeaderSubSectionInForm
                            text={"جواب استعلام گمرک"} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            background: "white", border: "1px solid black", borderRadius: "8px", padding: "8px",
                            boxSizing: "border-box"
                        }}>
                            <p>{`جواب استعلام در تاریخ ${"1388/8/8"}`}</p>
                            <p>{`به شماره ${123123123} ارسال گردید.`}</p>
                        </div>
                    </Grid>
                    <Grid>

                        <div>
                            <p >{"امتیازدهی به عملکرد سیستم"}</p>
                            <Rating
                                name="size-large"
                                value={getValueOfRating}
                                onChange={(event, newValue) => {
                                    setValueOfRating(newValue);
                                }}
                                size="large"
                            />

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <ButtonEffectShadow
                                text={"اتمام قرارداد"}
                                colors={colors.panel.buttonBackgroundGreen}
                                onclick={() => {
                                    navigate(links.panel.request.requestList)
                                }}
                                iconEnded={<DoneRounded />}
                            />

                        </div>
                    </Grid>

                </GridFromMUI>
            </div>
        </CardFromMUI>
    )
}
export default CustomsInquiryResult;