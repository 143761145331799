import { styled as styledMUI } from "@mui/material/styles";
import { Card, Select } from "@mui/material";

export const SelectFromMUI = styledMUI(Select)(({ theme }) => ({
    fontFamily: "vazirmatn-Regular",
    boxSizing:"border-box",
    // "&:focus-within":{
    //     outline:"2px solid #32a1ce",
    //     outlineOffset: "0px",
    // },
    "&:focus-within": {
        borderWidth: "2px"
    },

    "& svg": {
        color: "inherit",
        left: theme.direction == "rtl" ? "7px" : "unset",
        right: theme.direction == "rtl" ? "unset" : "7px",
    },
    "& fieldset": {
        borderColor: "currentcolor !important",
    },

    "& div": {
        color: "inherit",
        paddingRight: theme.direction == "rtl" ? "14px !important" : "32px !important",
        paddingLeft: theme.direction == "rtl" ? "32px" : "14px",
    },
    "& legend": {
        color: "inherit",
        textAlign: theme.direction == "rtl" ? "right" : "left",
        "& span": {
            color: "inherit",
        }

    },

}));