import links from "./links";
import {
    ApartmentRounded,
    AutoGraphRounded,
    BarChartOutlined, CallRounded, DateRangeOutlined, DesignServicesRounded,
    DiamondRounded,
    DiamondSharp,
    Diversity1Rounded,
    Diversity3Outlined, EmojiEventsOutlined,
    ExtensionRounded,
    FlagRounded,
    FlagSharp,
    FolderCopyOutlined,
    FolderSharp, GroupRounded,
    GroupSharp, HomeRounded,
    KeyRounded,
    KeySharp,
    LibraryBooksRounded,
    PasswordRounded,
    PersonRounded,
    RequestPageRounded,
    RollerShadesRounded,
    RollerShadesSharp,
    SettingsSharp,
    SettingsVoiceOutlined,
    SpeedOutlined,
    SupervisorAccountRounded,
    SvgIconComponent,
    TextIncreaseRounded,
    TimerOutlined,
    TimerSharp,
    TuneOutlined, Warning, WebRounded
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const ConfigComps = () => {
    const { t, i18n } = useTranslation();

    return (
        {
            pageNotFound: t('pageNotFound'),
            unit: {
                enum: {
                    gender: [
                        {
                            key: "male",
                            value: t('unit.enum.gender.0.value')
                        },
                        {
                            key: "female",
                            value: t('unit.enum.gender.1.value')
                        },
                        {
                            key: "other",
                            value: t('unit.enum.gender.2.value')
                        },
                    ],
                    week: [
                        {
                            key: "Saturday",
                            value: "شنبه"
                        },
                        {
                            key: "Sunday",
                            value: "یکشنبه"
                        },
                        {
                            key: "Monday",
                            value: "دوشنبه"
                        },
                        {
                            key: "Tuesday",
                            value: "سه‌شنبه"
                        },
                        {
                            key: "Wednesday",
                            value: "چهارشنبه"
                        },
                        {
                            key: "Thursday",
                            value: "پنج‌شنبه"
                        },
                        {
                            key: "Friday",
                            value: "جمعه"
                        },

                    ],
                }
            },

            panel: {
                authentication: {
                    login: {
                        header: t(''),
                        edittextUser: t(''),
                        edittextPassword: t(''),
                        dropDown: {}

                    }
                },
                appBar: {
                    sideBar: {
                        exit: t('panel.appBar.sideBar.exit'),
                    },
                },
                loginAndRegister: {
                    login: {
                        chooseRole: {
                            header: t('panel.loginAndRegister.login.chooseRole.header'),
                            roles: [
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.0.text'),
                                    id: 0
                                },
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.1.text'),
                                    id: 1
                                },
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.2.text'),
                                    id: 2
                                },
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.3.text'),
                                    id: 3
                                },

                            ]
                        },
                        header: {
                            text: t('panel.loginAndRegister.login.header.text')
                        },
                        textRedirect: [
                            {
                                text: t('panel.loginAndRegister.login.textRedirect.0.text'),
                                textLink: t('panel.loginAndRegister.login.textRedirect.0.textLink'),
                                redirectOnclick: links.loginAndRegister.register,
                            },
                            {
                                text: t('panel.loginAndRegister.login.textRedirect.1.text'),
                                textLink: t('panel.loginAndRegister.login.textRedirect.1.textLink'),
                                redirectOnclick: links.loginAndRegister.register,
                            }
                        ],
                        txtEditText: {
                            userName: {
                                title: t('panel.loginAndRegister.login.txtEditText.userName.title'),
                                isNecessary: true,
                                hintText: t('panel.loginAndRegister.login.txtEditText.userName.hintText'),
                                isMultiLine: false,
                            },
                            password: {
                                title: t('panel.loginAndRegister.login.txtEditText.password.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                        },
                        accept: t('panel.loginAndRegister.login.accept'),
                        notification: {
                            success: {
                                userAndPassTrue: t('panel.loginAndRegister.login.notification.success.userAndPassTrue'),
                            },
                            error: {
                                userAndPassError: t('panel.loginAndRegister.login.notification.error.userAndPassError'),
                            }
                        }
                    },
                    register: {
                        chooseRole: {
                            header: t('panel.loginAndRegister.register.chooseRole.header'),
                            roles: [
                                {
                                    text: t('panel.loginAndRegister.register.chooseRole.roles.0.text'),
                                    id: 0
                                },
                                {
                                    text: t('panel.loginAndRegister.register.chooseRole.roles.1.text'),
                                    id: 1
                                },

                            ]
                        },
                        header: {
                            text: t('panel.loginAndRegister.register.header.text')
                        },
                        textRedirect: [
                            {
                                text: t('panel.loginAndRegister.register.textRedirect.0.text'),
                                textLink: t('panel.loginAndRegister.register.textRedirect.0.textLink'),
                                redirectOnclick: links.loginAndRegister.login,
                            }
                        ],
                        txtEditText: {
                            phone: {
                                title: t('panel.loginAndRegister.register.txtEditText.phone.title'),
                                isNecessary: true,
                                hintText: t('panel.loginAndRegister.register.txtEditText.phone.hintText'),
                                isMultiLine: false,
                            },
                            otp: {
                                title: t('panel.loginAndRegister.register.txtEditText.otp.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                            password: {
                                title: t('panel.loginAndRegister.register.txtEditText.password.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                            rePassword: {
                                title: t('panel.loginAndRegister.register.txtEditText.rePassword.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                        },
                        acceptPhone: t('panel.loginAndRegister.register.acceptPhone'),
                        acceptOTP: t('panel.loginAndRegister.register.acceptOTP'),
                        acceptPassword: t('panel.loginAndRegister.register.acceptPassword'),
                        notification: {
                            success: {
                                phoneNumber: t('panel.loginAndRegister.register.notification.success.phoneNumber'),
                                OTP: t('panel.loginAndRegister.register.notification.success.OTP'),
                                passwordSaved: t('panel.loginAndRegister.register.notification.success.passwordSaved'),
                            },
                            error: {
                                phoneNumber: t('panel.loginAndRegister.register.notification.error.phoneNumber'),
                                OTP: t('panel.loginAndRegister.register.notification.error.OTP'),
                                confirmPasswordNotMatch: t('panel.loginAndRegister.register.notification.error.confirmPasswordNotMatch'),
                            },
                            warning: {
                                passwordIsSimple: t('panel.loginAndRegister.register.notification.warning.passwordIsSimple'),
                            }
                        }
                    }
                },


                sideBar: {
                    sections: [
                        {
                            title: "شرکت چیستا",
                            subtitles: [
                                {
                                    link: links.panel.account.accountManagment,
                                    text: "اطلاعات من",
                                    icon: ApartmentRounded
                                },
                                {
                                    link: links.panel.request.requestList,
                                    text: "قرارداد‌های من",
                                    icon: LibraryBooksRounded
                                },
                            ]

                        }
                    ]
                }


            }
        }
    )
}
