import { createSlice } from "@reduxjs/toolkit";
import { Direction } from "@mui/material";

export type InitialProgressbarInRootState = {
    value: number;
    isVisibility: boolean;
    timeOut: number;
    lastTimeMilisUpdate: number;
};
export type ProgressbarInRootStructure = {
    progressbarInRoot: InitialProgressbarInRootState
}

export const ProgressbarInRootSlice = createSlice({
    name: "progressbarInRoot",
    initialState: { value: -1, isVisibility: false, timeOut: 20000, lastTimeMilisUpdate: -1 } as InitialProgressbarInRootState,
    reducers: {
        isVisibilityProgressBar: (state, action: { payload: boolean }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.direction = state.direction == "rtl" ? "ltr" : "rtl";
            console.log("set visibility progress");
            console.log(state);
            console.log(action);
            state.isVisibility = action.payload;
            state.lastTimeMilisUpdate = (new Date()).getMilliseconds() + (new Date()).getSeconds() * 1000;

        },
        updateProgressBar: (state, action: { payload: number }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.direction = state.direction == "rtl" ? "ltr" : "rtl";
            console.log("update value of progress");
            console.log(state);
            console.log(action);
            state.value = action.payload;
            state.lastTimeMilisUpdate = (new Date()).getMilliseconds() + (new Date()).getSeconds() * 1000;

        },
    },
});

// Action creators are generated for each case reducer function
export const { isVisibilityProgressBar, updateProgressBar } = ProgressbarInRootSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const SelectedProgressbarInRoot = (state: ProgressbarInRootStructure) => {
    console.log("ProgressbarInRoot called");
    console.log(state.progressbarInRoot);
    return state.progressbarInRoot;
};

export default ProgressbarInRootSlice.reducer;