import configResponsive, {enumListSizeDevices} from "../config/responsive/configResponsive";

export const HelperResponsive = () => {
    if (window.innerWidth < configResponsive.display_int.small_mobile) {
        return(enumListSizeDevices.small_mobile);
    } else if (window.innerWidth < configResponsive.display_int.mobile) {
        return(enumListSizeDevices.mobile);
    } else if (window.innerWidth < configResponsive.display_int.tablet) {
        return(enumListSizeDevices.tablet);
    } else if (window.innerWidth < configResponsive.display_int.desktop) {
        return(enumListSizeDevices.desktop);
    } else {
        return(enumListSizeDevices.tv);
    }
}