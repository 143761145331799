import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { CardFromMUI } from "../../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import HeaderSubSectionInForm from "../../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import { interfaceServiceInquiryResult } from "./interfaceServiceInquiryResult";
import { useState } from "react";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import { ArrowLeftRounded } from "@mui/icons-material";

function ServiceInquiryResult(componentsParams: interfaceServiceInquiryResult) {
    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 6 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <HeaderSubSectionInForm
                            text={"نتیجه استعلام خدمات"} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row", alignItems: "center",
                            justifyContent: "start",
                            gap: "calc(1vw + 1px)",
                            boxSizing: "border-box",
                        }}>
                            <p style={{ fontWeight: "600", color: "black" }}>
                                {"برند:"}
                            </p>
                            <p style={{ color: "black" }}>
                                qqqqqq
                            </p>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row", alignItems: "center",
                            justifyContent: "start",
                            gap: "calc(1vw + 1px)",
                            boxSizing: "border-box",
                        }}>
                            <p style={{ fontWeight: "600", color: "black" }}>
                                {"تیپ:"}
                            </p>
                            <p style={{ color: "black" }}>
                                qqqqqq
                            </p>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row", alignItems: "center",
                            justifyContent: "start",
                            boxSizing: "border-box",
                            gap: "calc(1vw + 1px)"
                        }}>
                            <p style={{ fontWeight: "600", color: "black" }}>
                                {"سال ساخت:"}
                            </p>
                            <p style={{ color: "black" }}>
                                qqqqqq
                            </p>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row", alignItems: "center",
                            justifyContent: "start",
                            boxSizing: "border-box",
                            gap: "calc(1vw + 1px)"
                        }}>
                            <p style={{ fontWeight: "600", color: "black" }}>
                                {"مدل:"}
                            </p>
                            <p style={{ color: "black" }}>
                                qqqqqq
                            </p>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row", alignItems: "center",
                            justifyContent: "start",
                            boxSizing: "border-box",
                            gap: "calc(1vw + 1px)"
                        }}>
                            <p style={{ fontWeight: "600", color: "black" }}>
                                {"توضیحات:"}
                            </p>
                            <p style={{ color: "black" }}>
                                qqqqqq
                            </p>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end",
                            boxSizing: "border-box",
                        }}>
                            <ButtonEffectShadow
                                text={"مرحله بعد"}
                                colors={colors.panel.buttonBackgroundGreen}
                                onclick={() => {
                                    componentsParams.callbackGetInfoAgain()
                                }}
                                iconEnded={<ArrowLeftRounded />}
                            />

                        </div>
                    </Grid>


                </GridFromMUI>
            </div>
        </CardFromMUI>
    )
}
export default ServiceInquiryResult;