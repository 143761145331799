import { TextField, Theme } from "@mui/material";
import colors from "../../config/colors";
import { useTheme } from "@mui/material/styles";
import { InterfaceDateTimePickHorizentalMe } from "./interfaceDateTimePickHorizentalMe";
import { styled as styledMUI } from "@mui/material/styles";
import { TextFieldFromMUI } from "../MUIConfig/TextFieldFromMUI";
import { useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";
import { DateTimePickerFromMUI } from "../MUIConfig/DateTimePickerFromMUI";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { randomInt } from "crypto";
import { CardFromMUI } from "../MUIConfig/CardFromMUI";


const DivEditTextWithTitle = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "stretch",
    width: "100%",
    border: "1px solid #bbb",
    borderRadius: "8px",
    overflow: "hidden",
}));

export const PText = styledMUI("p", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            fontSize: "1.3rem",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});
export const H6Text = styledMUI("h6", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            marginBlockStart: "5%",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});

export const DivListAllItem = styledMUI("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
    paddingInline: "10px",
    flexDirection: "row",
    color: "black",
}));
function DateTimePickHorizentalMe(paramsComponent: InterfaceDateTimePickHorizentalMe) {
    const [getDefultTime, setDefultTime] = useState<number | undefined>(paramsComponent.valueSecound);
    useEffect(() => {

        setDefultTime(paramsComponent.valueSecound ? (paramsComponent.valueSecound) : undefined)
    }, [paramsComponent.valueSecound])
    useEffect(() => {
        console.log("safaaaajonnnn", getDefultTime)
    }, [getDefultTime])
    const mainTheme = useTheme();
    return (
        <DivEditTextWithTitle>
            <CardFromMUI style={{ background: "linear-gradient(0deg, rgba(200, 200, 206, 1) 0%, rgba(235, 235, 235, 1) 100%)", borderEndEndRadius: "0px", borderStartEndRadius: "0px" }}>

                <DivListAllItem>
                    <PText isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</PText>
                </DivListAllItem>
            </CardFromMUI>
            <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                <DateTimePickerFromMUI
                    isHintTextCenter={false}
                    isValueMustLtr={paramsComponent.isDataMustLtr ?? false}
                    label={paramsComponent.hintText}
                    onChange={(e: any) => paramsComponent.onChange(e)}
                    value={getDefultTime}
                    disabled={paramsComponent.disable}
                    backgroundColor={paramsComponent.backGroundColor}
                    isSmallHintShow={paramsComponent.isSmallHintShow}
                    styleMain={paramsComponent.styleMain} />
            </LocalizationProvider>
        </DivEditTextWithTitle>
    )
}

export default DateTimePickHorizentalMe;
