import { createSlice } from "@reduxjs/toolkit";
import { Direction } from "@mui/material";
import { enumTypeOfAlert } from "../components/MUIConfig/alert_popup/interfaceAlertPopup";

export type InitialAlertState = {
    text: string;
    typeAlert: string;
    lastTimeecondsUpdate:number;
}
export type AlertStructure = {
    alerts: InitialAlertState
}
export const alertStateModeSlice = createSlice({
    name: "alerts",
    initialState: { text: "", typeAlert: enumTypeOfAlert.error } as InitialAlertState,
    reducers: {
        ShowAlert: (state, action: { payload: InitialAlertState }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.direction = state.direction == "rtl" ? "ltr" : "rtl";
            // console.log("SetAlert");
            // console.log(state);
            // console.log(action.payload);
            state.text = action.payload.text;
            state.typeAlert = action.payload.typeAlert;
            state.lastTimeecondsUpdate=action.payload.lastTimeecondsUpdate;

        },
    },
});

// Action creators are generated for each case reducer function
export const { ShowAlert } = alertStateModeSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const SelectedAlert = (state: AlertStructure) => {
    // console.log("selectAlert called");
    // console.log(state.alerts);
    return state
};

export default alertStateModeSlice.reducer;