import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceCreateRequest, interfaceGetApiContractInfo } from "./interfaceCreateRequest";
import { Square } from "@mui/icons-material";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import colors from "../../../../config/colors";
import CarInformation from "./carInformation/CarInformation";
import InvoiceOfCard from "./invoiceOfCard/InvoiceOfCard";
import CarContract from "./carContract/CarContract";
import MainInvoice from "./mainInvoice/MainInvoice";
import ServiceInquiryResult from "./serviceInquiryResult/ServiceInquiryResult";
import InquiryLatter from "./inquiryLatter/InquiryLatter";
import CustomsInquiryResult from "./customsInquiryResult/CustomsInquiryResult";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { interfaceApiGetInformationTariffs } from "./carInformation/interfaceCarInformation";
export const countOfStep = [
    { number: "۱", title: "اطلاعات خودرو" },
    { number: "۲", title: "تایید قرارداد" },
    { number: "۳", title: "فاکتور" },
    { number: "۴", title: "نتیجه خدمات" },
    { number: "۵", title: "نامه استعلام گمرک" }, { number: "۷", title: "جواب استعلام گمرک" }
]
function CreateRequest(pagesParams: interfaceCreateRequest) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { idContract } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getContractInFormation, setContractInFormation] = useState<interfaceGetApiContractInfo>();
    const [getStepNow, setStepNow] = useState<number>(0);
    const refDivForSteps = useRef<HTMLInputElement>(null);
    const [getStepSize, setStepSize] = useState<number>(0);

    const apiCallGetInforamationOfContract = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.contract.getInformationTheContract;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url + `${idContract}`, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceGetApiContractInfo;
                setContractInFormation(t);
                setStepNow(t.data.contract_stage - 1)
                dispatch(ShowAlert({ text: "اطلاعات قرارداد دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callbackGetInfoAgain = () => {
        apiCallGetInforamationOfContract();
    }
    useEffect(() => {
        setStepSize(Number(refDivForSteps.current ? refDivForSteps.current.offsetWidth : 0) / countOfStep.length);
    }, [refDivForSteps.current]);
    useEffect(() => {
        console.log("safaaaaa")
        if (idContract) {
            apiCallGetInforamationOfContract();
        }
    }, [idContract]);
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <div
                ref={refDivForSteps}
                style={{
                    width: "100%", display: "flex", flexDirection: "row",
                    alignItems: "start", justifyContent: "space-around",
                    marginBottom: "20px",
                }}>
                {countOfStep.map((title, index, array) => {
                    var isActive = (getStepNow >= index)
                    return (
                        <div style={{
                            width: `${getStepSize}px`,
                            display: "flex",
                            alignItems: "center", justifyContent: "start",
                            position: "relative", flexDirection: "column",
                        }}>
                            <div style={{
                                position: "absolute", top: `min(${getStepSize / 4}px , 28px)`,
                                transform: "translate(0px,-2px)",
                                width: "100%",
                                height: "min(1vh , 4px)",
                                background: isActive ? colors.yellow.yellowSharp : colors.black.gray
                            }} />
                            <ImageSquare width={`min(${getStepSize / 2}px , 56px)`} element={<p style={{ marginTop: "0.2rem" }}>{title.number}</p>}
                                color={isActive ? colors.black.black : colors.black.white}
                                background={isActive ? colors.yellow.yellowSharp : colors.black.gray}
                                borderRadius="300px"
                                border="1px solid #555"
                            />
                            <p style={{
                                textAlign: "center", fontSize: "0.8rem",
                                color: isActive ? colors.yellow.yellowDark : colors.black.black
                            }}>{title.title}</p>
                        </div>
                    )
                })}
            </div>
            {(getStepNow == 0 && (getContractInFormation || !idContract)) ?
                <CarInformation
                    typeOfDisplay={pagesParams.typeOfDisplay}
                    callbackGetInfoAgain={callbackGetInfoAgain} /> : undefined}
            {/* {
                (getStepNow == 1 && getContractInFormation) ?
                    <InvoiceOfCard
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation} />
                    : undefined
            } */}
            {
                (getStepNow == 1 && getContractInFormation) ?
                    <CarContract
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation}
                    /> : undefined
            }
            {
                (getStepNow == 2 && getContractInFormation) ?
                    <MainInvoice
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation}
                    /> : undefined
            }
            {
                (getStepNow == 3 && getContractInFormation) ?
                    <ServiceInquiryResult
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation}
                    /> : undefined
            }
            {
                (getStepNow == 4 && getContractInFormation) ?
                    <InquiryLatter
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation}
                    /> : undefined
            }
            {
                (getStepNow == 5 && getContractInFormation) ?
                    <CustomsInquiryResult
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        ServerGetInfoAtFirstTime={undefined}
                        callbackGetInfoAgain={callbackGetInfoAgain}
                        getInformationOfContract={getContractInFormation}
                    /> : undefined
            }
        </div>
    )
}
export default CreateRequest;