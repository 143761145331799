import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { interfaceButtonEffectShadow } from "./interfaceButtonEffectShadow";
import { Button } from "@mui/material";
import colors from "../../config/colors";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
function ButtonEffectShadow(paramsComponent: interfaceButtonEffectShadow) {
    const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

        position: "absolute",
        top: "0",
        left: "0",
        pointerEvents: "none",
        background: "red",
        width: "120%",
        height: "101%",
        zIndex: "-1",
        transformOrigin: "0% 100%",
        transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
        transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
    }));
    const ButtonMain = styledMUI(Button)(({ theme }) => ({
        color: paramsComponent.colors ? paramsComponent.colors.text : colors.panel.buttonYellowBackground.text,
        background: paramsComponent.colors ? paramsComponent.colors.background : colors.panel.buttonYellowBackground.background,
        pointerEvents: "auto",
        borderRadius: "2em",
        paddingInline: paramsComponent.styleMainDiv?.paddingInline ?? "3rem",
        lineHeight: paramsComponent.styleMainDiv?.lineHeight,
        textAlign: "center",
        border: "1px solid",
        overflow: "hidden",
        position: "relative",
        zIndex: "2",
        fontSize: paramsComponent.styleMainDiv?.fontSize,

        '& .MuiButton-endIcon': {
            marginRight: theme.direction == "rtl" ? "4px" : "-4px",
        },
        '&:hover': {
            color: paramsComponent.colors ? paramsComponent.colors.hoverText : colors.panel.buttonYellowBackground.hoverText,
            'div': {
                position: "absolute",
                top: "0",
                left: "0",
                // width: "120%",
                height: "100%",
                background: paramsComponent.colors ? paramsComponent.colors.hoverBackground : colors.panel.buttonYellowBackground.hoverBackground,
                zIndex: "-1",
                transform: "rotate(0deg) translate3d(0, 0em, 0)",
            }
        }
    }));

    return (
        <ButtonMain
            size={paramsComponent.size ?? "large"}
            onClick={paramsComponent.onclick}
            endIcon={paramsComponent.iconEnded}
            style={{ whiteSpace: "nowrap", ...paramsComponent.styleMainDiv }}
        // style={{fontSize:"1px !important"}}
        >
            <ShadowButtonProducts />

            {paramsComponent.element ?? paramsComponent.text}
        </ButtonMain >
    )
}
export default ButtonEffectShadow;