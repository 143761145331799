/**
 * Initialze and config i18n.
 *
 * Call from the app index.
 *
 * @module
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Locales } from "./locales";
// import { locales } from "./index";
const locales =["en", "fa"] as const;

// Variables
const resources: { [locale in Locales]?: any } = {};
locales.forEach(
  (locale) =>
    (resources[locale] = {
      // TODO manage to add multiple namespaces
      translation: require(`./locales/${locale}/translation.json`),
    })
);

// i18n definition
i18n.use(initReactI18next).init({
  lng: locales[0],
  supportedLngs: locales,
  interpolation: { escapeValue: false },
  resources: resources,
  fallbackLng: { default: [locales[0]] },
});
