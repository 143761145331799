import { styled as styledMUI } from "@mui/material/styles";
import { TextField } from "@mui/material";
import colors from "../../config/colors";
import { DateTimePicker } from "@mui/x-date-pickers";

export const DateTimePickerFromMUI = styledMUI(DateTimePicker, { shouldForwardProp: (prop) => prop !== 'isValueMustLtr' && prop !== 'isSmallHintShow' && prop !== 'isHintTextCenter' && prop !== 'backgroundColor' && prop !== 'styleMain' })<{ styleMain?: React.CSSProperties, isHintTextCenter: boolean, isValueMustLtr: boolean, isSmallHintShow?: boolean, backgroundColor?: string }>(({
    theme,
    isValueMustLtr,
    isSmallHintShow,
    isHintTextCenter,
    backgroundColor,
    styleMain,
}) => {
    let isSpanMustShow = isSmallHintShow ?? true;
    let diraction = isValueMustLtr ? "ltr" : theme.direction;
    let background = backgroundColor ?? undefined
    return (
        {
            flex: 1,
            "&:focus-within": {
                outline: "2px solid #32a1ce",
                outlineOffset: "0px",
            },
            // background: colors.screens.contactus.backgroundColorEditText,
            // color: colors.screens.aboutUs.imageAndContent.headerText,

            // "& div.MuiOutlinedInput-root": {
            //     background: colors.screens.contactus.backgroundColorEditText,
            // },

            "& textarea": {
                direction: diraction,
                outlineWidth: "0px",
            },
            "& input": {
                direction: diraction,
                outlineWidth: "0px",
                padding: "8.5px 14px",
            },
            "& fieldset": {
                borderWidth: "0px !important",
                outlineWidth: "0px",
            },
            "& label": {
                color: "inherit"
            },

            "& label.MuiFormLabel-root": {
                direction: diraction,
                right: diraction == "rtl" ? 0 : "unset",
                left: isHintTextCenter ? "50%" : undefined,
                top: "50%",
                transform: isHintTextCenter ? (`translate(${diraction == "rtl" ? "50%" : "-50%"}, -50%)`) :
                    (`translate(${diraction == "rtl" ? "-8px" : "8px"}, -50%)`),
            },
            "& svg": {
                color: "inherit"
            },
            "& button": {
                color: "inherit !important"
            },
            "& div": {
                color: "inherit !important"
            },

            "& label.MuiInputLabel-shrink": {

                right: diraction == "rtl" ? "0" : "unset",
                left: diraction == "rtl" ? "unset" : "0",
                top: "0",
                transform: `scale(0.75) translate(${diraction == "ltr" ? "calc(5px + 50%)" : "calc(-5px + -26%)"}, -50%)`,
                display: isSpanMustShow ? undefined : "none",

            },
            "& legend": {
                textAlign: diraction == "ltr" ? "left" : "right",
                "& span": {
                    display: isSpanMustShow ? undefined : "none",
                }

            },
            "& div.MuiInputBase-root": {
                background: background,
                outlineWidth: "0px",
                color: "inherit",
            },
            ...styleMain,


        })
});