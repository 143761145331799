import { InterfaceNavbarMainPage, enumLanguage } from "./InterfaceNavbarMainPage";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { Button, FormControl, InputLabel, MenuItem, Select, useScrollTrigger } from "@mui/material";
import { DragHandleSharp, DragIndicatorRounded, HomeMaxRounded, HomeRounded, PersonOutlineOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import colors from "../../../config/colors";

import links from "../../../config/links";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { HelperResponsive } from "../../../helper/Helper";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import { ConfigComps } from "../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SetDirectionOfTheme } from "../../../store/themeModeSlice";
import { useTranslation } from "react-i18next";
import { SelectFromMUI } from "../../MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../MUIConfig/MenuItemFromMUI";
import SideBarMain from "../sideBarProductDetails/SideBarMain";



const Container = styledMUI("div")(({ theme }) => ({
    position: "fixed",
    width: "100vw",
    top: 0,
    transition: "top 1s",
    transitionTimingFunction: "ease-out",
    zIndex: 10,
}));
const Nav = styledMUI("div")(({ theme }) => ({

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "-webkit-fill-available",
    height: "100%",
    borderBottom: `1px solid ${"blue"}`,
    padding: "0px 50px",

}));
const RefNameCompany = styledMUI("h2")(({ theme }) => ({
    marginRight: "100px",
    transition: "0.3s all",
    lineHeight: "50px",
    color: "#fff",
}));
const NavContent = styledMUI("div")(({ theme }) => ({
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    // padding: NAVBAR_PADDING_HEIGHT_QUARTER_UNIT,
    [theme.breakpoints.down("sm")]: {
        padding: "0.15rem",
        width: "100%",
    },
}));
const LogoContainer = styledMUI("div")(({ theme }) => ({
    // fontSize: ITEM_FONT_SIZE_UNIT,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        // fontSize: ITEM_FONT_SIZE_UNIT_MOBILE,
    }
}));
const ItemListContainer = styledMUI("ul")(({ theme }) => ({
    listStyle: "none",
    width: "",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        display: "none",
    }
}));


const ItemContainer = styledMUI('p', { shouldForwardProp: (prop) => prop !== "color" && prop !== 'selected', })<{ selected?: boolean; color?: string }>(({
    theme,
    selected
}) => ({
    [theme.breakpoints.up('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-medium'} !important`,
        // fontSize: `${selected ? '1.56rem' : '1.37rem'} !important`,
    },
    [theme.breakpoints.down('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-Regular'} !important`,
        // fontSize: `${selected ? '1.37rem' : '1rem'} !important`,
    },
    margin: "0",
    textDecoration: "blink",
    border: "none",
    cursor: "pointer",
    color: "red"
}));
const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

    // background: "red",
    position: "absolute",
    top: "0",
    left: "0",
    pointerEvents: "none",

    width: "120%",
    height: "101%",
    zIndex: "-1",
    transformOrigin: "0% 100%",
    transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
    transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const ButtonProducts = styledMUI(Button)(({ theme }) => ({
    color: "red",
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    margin: "0",
    textAlign: "center",
    border: "1px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    '&:hover': {
        color: "blue",
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: "#fff",
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));
const StyledBadge = styledMUI(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 24,
        // background: "red",

        // border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const SelectMenuLanguage = styledMUI(SelectFromMUI)(({ theme }) => ({
    "fieldset": {
        border: "0"
    },
    "svg": {
        // color: "red"
    }
}));
const HamburgerMenuBtn = styledMUI(IconButton)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "none",
    }
}));



// const ImgLogoVisibility = styled("img")`
//   visibility: visible;
//   ${mobile({display: "none"})}
// `;

export const NavbarMainPage = (paramsComponent: InterfaceNavbarMainPage) => {
    const navigate = useNavigate();
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    var confComps = ConfigComps();

    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 10 });

    // initialize dispatch variable
    const dispatch = useDispatch();
    const [getLanguage, setLanguage] = useState<enumLanguage>(mTheme.direction == "ltr" ? enumLanguage.en : enumLanguage.fa);
    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };

    const [isShowHamburgerMenu, setIsShowHamburgerMenu] = useState<boolean>(false);


    const onClickInHamburgerMenu = () => {
        setIsShowHamburgerMenu(true);
    }
    const onCloseHamburgerMenu = () => {
        setIsShowHamburgerMenu(false);
    }
    const handleChangeLanguge = (event: any) => {
        console.log(event);
        setLanguage(event.target.value)
        i18n.changeLanguage(mTheme.direction == "rtl" ? "en" : "fa");
        dispatch(SetDirectionOfTheme(mTheme.direction == "rtl" ? "ltr" : "rtl"));
    };

    return (
        <Container>
            <Nav>
                <NavContent>

                    <SideBarMain
                        isHamburgerMenuShow={isShowHamburgerMenu}
                        typeOfDisplay={HelperResponsive()}
                        callBackOnClose={onCloseHamburgerMenu}
                        isHamburgerMenuStaticAndFixPosition={false}
                        sections={paramsComponent.sections}
                    />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: "1vw",
                    }}>
                        <HamburgerMenuBtn
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onClickInHamburgerMenu}
                        >
                            <MenuIcon fontSize={"large"} />
                        </HamburgerMenuBtn>
                        {/* <Link to={`${links.companyPage}${mTheme.direction == "rtl" ? "fa" : "en"}`} style={{ textDecoration: "none", }} >
                            <img src={confComps.navbarMainPage.logo} style={{ height: "max(90px , 9vw)" }}></img>
                        </Link> */}
                    </div>



                </NavContent>
            </Nav>
        </Container>
    );

}