import { Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material";
import { CardFromMUI } from "../../../../../components/MUIConfig/CardFromMUI";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import HeaderSubSectionInForm from "../../../../../components/headerSubsectionInForm/HeaderSubSectionInForm";
import { interfaceCarContract } from "./interfaceCarContract";
import { useEffect, useState } from "react";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import { ArrowLeftRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import links from "../../../../../config/links";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceEntityOfTariffs, interfaceApiGetInformationTariffs } from "../carInformation/interfaceCarInformation";
import { interfaceApiGetInformationAccount } from "../../../accout/accountManagment/interfaceAccountManagment";

function CarContract(componentsParams: interfaceCarContract) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAcceptedSentence, setAcceptedSentence] = useState<boolean>(false);
    const [getApiGetInformationTariff, setApiGetInformationTariff] = useState<interfaceEntityOfTariffs>();
    const [getAccountCompany, setAccountCompany] = useState<interfaceApiGetInformationAccount>();

    const apiCallGetAccountInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.getAccountCompanyInfo;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceApiGetInformationAccount;
                setAccountCompany(t)
                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const apiCallGetListAllTariffs = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.contract.getCompanyTariff;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceApiGetInformationTariffs;
                setApiGetInformationTariff(t.data.filter(taref => taref.id == componentsParams.getInformationOfContract.data.car_tariff)[0]);

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const apiCallForUpgradeContract = async () => {
        var urlCall = urlsList.panel.contract.patchInformationTheContract;
        if (!getAcceptedSentence) {
            dispatch(ShowAlert({ text: `باید قرارداد را تایید نمایید`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const ObjSend = {
            "is_confirmed": true
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url + `${componentsParams.getInformationOfContract.data.id}`, mtheme),
            urlCall.method, urlCall.isTokenNecessary, ObjSend, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // navigate(links.panel.request.createRequest + `/${response.data.id}`)
                componentsParams.callbackGetInfoAgain();
                dispatch(ShowAlert({ text: "قرارداد تایید شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        apiCallGetListAllTariffs();
        apiCallGetAccountInfo();
    }, [])
    return (
        <CardFromMUI style={{
            width: "100%", background: "#1111",
            paddingBlock: "calc(1vh + 12px)",
            paddingInline: "calc(1vw + 12px)",
            boxSizing: "border-box",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <HeaderSubSectionInForm
                            text={"قرارداد"} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            background: "white", border: "1px solid black", borderRadius: "8px", padding: "8px",
                            boxSizing: "border-box"
                        }}>

                            <p style={{ direction: "rtl" }}>{`شرکت "${getApiGetInformationTariff?.car_type.brand.title}" برند "${getApiGetInformationTariff?.car_type.title}" سال ساخت "${getApiGetInformationTariff?.year.title}" شماره ساخت(VIN): "${componentsParams.getInformationOfContract.data.car_vin}"`}</p>
                            <p>{`قیمت "${getApiGetInformationTariff?.price}" می‌باشد.`}</p>
                            <p>{`اگر با قیمت موافق هستید لطفا به مرحله بعد بروید!`}</p>
                            <p>{`این قرارداد بین شرکت چیستا و شرکت `}{getAccountCompany?.data.company_name}</p>


                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event, checked) => { setAcceptedSentence(checked) }}
                                    value={getAcceptedSentence}
                                    style={{
                                        color: "inherit"
                                    }} />
                            }
                            style={{
                                color: "black",
                                marginRight: "-11px",
                            }}

                            label={"موارد بالا را مطالعه کردم و قبول دارم."}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <ButtonEffectShadow
                                text={"مرحله بعد"}
                                colors={getAcceptedSentence ? colors.panel.buttonBackgroundGreen : colors.panel.buttonDeactiveBackgroundDeactive}
                                onclick={apiCallForUpgradeContract}
                                iconEnded={<ArrowLeftRounded />}
                            />

                        </div>
                    </Grid>

                </GridFromMUI>
            </div>
        </CardFromMUI>
    )
}
export default CarContract;