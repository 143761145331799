import { AddRoadRounded, AddRounded } from "@mui/icons-material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { interfaceApiGetListRequest, interfaceRequestsList } from "./interfaceRequestsList";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import links from "../../../../config/links";
import colors from "../../../../config/colors";
import Table from "@mui/material/Table";
import { styled as styledMUI } from "@mui/material/styles";
import { Button, ButtonBase } from "@mui/material";
import { EnumGender } from "../../../../utils/Gender";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { interfaceGetApiContractInfo } from "../createRequest/interfaceCreateRequest";
import { useEffect, useState } from "react";
import { countOfStep } from "../createRequest/CreateRequest";
import { interfaceApiGetInformationTariffs, interfaceEntityOfTariffs } from "../createRequest/carInformation/interfaceCarInformation";
import { ButtonFromMUI } from "../../../../components/MUIConfig/ButtonFromMUI";
enum enumStateService {
    pending = "pd"
}
const TitleP = styledMUI("th")(({ theme }) => ({
    fontFamily: "vazirmatn-bold",
    whiteSpace: "nowrap",
    padding: "8px calc(1vw + 8px)",

}));
const StateTicket = styledMUI(Button, { shouldForwardProp: (prop) => prop !== 'stateTicket' })<{ stateService: enumStateService; }>(({
    theme,
    stateService
}) => ({
    whiteSpace: "nowrap",
    color: stateService == enumStateService.pending ? "black" : "blue",
    margin: "8px",
}));
const DateP = styledMUI("p")(({ theme }) => ({
    whiteSpace: "nowrap",
    color: "green",
    margin: "8px",
}));
const CellTD = styledMUI("td")(({ theme }) => ({
    padding: "8px calc(1vw + 8px)",
    textAlign: "center",
    // border: "1px solid #f0f0f0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}));


function RequestsList(pagesParams: interfaceRequestsList) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getApiContractsList, setApiContractsList] = useState<interfaceApiGetListRequest>();
    const [getApiGetInformationTariff, setApiGetInformationTariff] = useState<interfaceApiGetInformationTariffs>();

    const apiCallGetListRequest = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.contract.getListContracts;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceApiGetListRequest;
                setApiContractsList(t);
                dispatch(ShowAlert({ text: "اطلاعات قراردادها دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const apiCallGetListAllTariffs = async () => {
        dispatch(isVisibilityProgressBar(true));
        var urlCall = urlsList.panel.contract.getCompanyTariff;
        await RequestHandler(dispatch, HandlerLangUrl(urlCall.url, mtheme),
            urlCall.method, urlCall.isTokenNecessary, undefined, profileInStore, urlCall.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                const t = response as interfaceApiGetInformationTariffs;
                setApiGetInformationTariff(t);

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        apiCallGetListRequest();
        apiCallGetListAllTariffs();
    }, []);

    const headerTitles = [{ name: "ردیف" }, { name: "شماره قرارداد" }, { name: "برند" },
    { name: "تیپ" }, { name: "سال" }, { name: "وضعیت پرداخت" }, { name: "مبلغ" }
        , { name: "وضعیت قرارداد" }];
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{
                width: "100%", display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", marginBottom: "20px"
            }}>
                <h5>{"لیست درخواست‌ها"}</h5>
                <ImageSquare width={"calc(1vw + 40px)"}
                    iconSvg={AddRounded}
                    background={colors.yellow.yellowSharp}
                    callbackOnclick={() => {
                        navigate(links.panel.request.createRequest)
                    }}
                    borderRadius="400px" />

            </div>
            <div style={{
                maxWidth: "100%",
                overflowY: "hidden",
                margin: "8px calc( 1vW + 8px)",
                border: "1px solid #a0a0a0",
                boxSizing: "border-box",
                borderRadius: "12px",
                background: "white",
                maxHeight: "70vh",
                overflow: "scroll",
                position: "relative",
            }}>
                <table style={{
                    borderCollapse: "collapse",
                }}>
                    <thead >
                        <tr style={{
                            borderBottom: `1px solid #e0e0e0`,
                            position: "sticky",
                            top: "0px",
                            background: "white",
                            boxShadow: '0px 1px 3px 0px #cccccc',
                        }}>
                            {headerTitles.map((value, index, array) => {
                                if (index == 0) {
                                    return (
                                        <TitleP key={index}
                                            style={{ padding: "8px", boxSizing: "border-box", border: "1px solid #e0e0e0" }}>{value.name}</TitleP>
                                    )
                                }
                                else {
                                    return (
                                        <TitleP key={index}
                                            style={{
                                                padding: "8px",
                                                boxSizing: "border-box"
                                            }}>{value.name}</TitleP>
                                    )
                                }
                            })}


                        </tr>
                    </thead>
                    <tbody style={{
                    }}>
                        {getApiContractsList?.data.map((contract, index, arr) => {
                            return (
                                <tr style={{
                                    borderBottom: `1px solid #e0e0e0`,
                                    boxSizing: "border-box",

                                }}>
                                    <CellTD style={{ border: "1px solid #e0e0e0", boxSizing: "border-box" }}>{index + 1}</CellTD>

                                    <CellTD>{contract.contract_number}</CellTD>

                                    <CellTD>{getApiGetInformationTariff?.data.filter(tar => tar.id == contract.car_tariff)[0].car_type.title}</CellTD>

                                    <CellTD>{getApiGetInformationTariff?.data.filter(tar => tar.id == contract.car_tariff)[0].car_type.brand.title}</CellTD>

                                    <CellTD>{getApiGetInformationTariff?.data.filter(tar => tar.id == contract.car_tariff)[0].year.title}</CellTD>

                                    <CellTD style={{ color: "red" }}>{"پرداخت نشده"}</CellTD>
                                    <CellTD>{getApiGetInformationTariff?.data.filter(tar => tar.id == contract.car_tariff)[0].price}</CellTD>

                                    <CellTD>
                                        {/* <Button style={{
                                            background: colors.green.active,
                                            color: "black",
                                            zIndex: "0",
                                        }}>
                                            {countOfStep[contract.contract_stage - 1].title}
                                        </Button> */}
                                        <Button variant="contained" style={{ position: "unset" }}
                                            onClick={() => {
                                                navigate(links.panel.request.createRequest + `/${contract.id}`)
                                            }}>
                                            {countOfStep[contract.contract_stage - 1].title}

                                        </Button>
                                    </CellTD>
                                </tr>
                            )
                        })

                        }


                    </tbody>

                </table>
            </div>
        </div >
    )
}
export default RequestsList;