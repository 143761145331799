import colors from "../../../../config/colors";
import { interfaceAccountStatus } from "./interfaceAccountStatus";



function AccountStatus(pagesParams: interfaceAccountStatus) {
    return (
        <div style={{
            width: "100%",
            marginTop: "4vh",
            border: "1px solid #333",
            borderRadius: "8px",
            paddingInline: "calc(1vw + 12px)",
            paddingBlock: "calc(1vh + 12px)",
            boxSizing: "border-box",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <h5 style={{ color: colors.yellow.yellowDark }}>{"در انتظار تایید می‌باشید."}</h5>
            <p style={{ color: colors.yellow.yellowDark }}>{"...لطفا صبر نمایید"}</p>
        </div>
    )
}
export default AccountStatus;