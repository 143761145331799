import { styled as styledMUI } from "@mui/material/styles";
import { Card, Dialog, Select } from "@mui/material";

export const DialogFromMUI = styledMUI(Dialog, { shouldForwardProp: (prop) => prop !== 'background' })<{ background?: string; }>(({
    theme,
    background
}) => ({
    fontFamily: "vazirmatn-Regular",
    boxSizing: "border-box",
    "& div .MuiDialog-paper": {
        borderRadius: "8px",
        border: "1px solid white",
        backgroundColor: background,
    },

}));