import colors from "../../../../config/colors";
import { interfaceAcceptedAccount } from "./interfaceAcceptedAccount";


function AcceptedAccount(pagesParams: interfaceAcceptedAccount) {
    return (
        <div style={{
            width: "100%",
            marginTop: "4vh",
            border: "1px solid #333",
            borderRadius: "8px",
            paddingInline: "calc(1vw + 12px)",
            paddingBlock: "calc(1vh + 12px)",
            boxSizing: "border-box",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <h5 style={{ color: colors.green.active }}>{"حساب شما با موفقیت ایجاد شده است"}</h5>
        </div>
    )
}
export default AcceptedAccount;