import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route } from "react-router-dom";
import { SelectedProfile } from "../store/profileStateSlice";
import links from "../config/links";

const PrivateRedirectToLogin = (props: any) => {
  const token = localStorage.getItem("auth");
  const ProfileInStore = useSelector(SelectedProfile);


  return (ProfileInStore.lastUpdatePosixTime < 0 || (ProfileInStore.token == undefined))  ? <Navigate to= {links.loginAndRegister.register} /> : <Outlet />;
  // <Route {...ProfileInStore == null ? { ...props } : { ...props }} />
  // <Route path={links.panel.loginAndRegister.login}
  //                         element={<LoginPage />} />


};

export default PrivateRedirectToLogin;