import { TextField, Theme } from "@mui/material";
import colors from "../../config/colors";
import { useTheme } from "@mui/material/styles";
import { InterfaceEditTextHorizontal } from "./interfaceEditTextHorizontal";
import { styled as styledMUI } from "@mui/material/styles";
import { TextFieldFromMUI } from "../MUIConfig/TextFieldFromMUI";
import { useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";
import { CardFromMUI } from "../MUIConfig/CardFromMUI";


const DivEditTextWithTitle = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "stretch",
    width: "100%",
    border: "1px solid #bbb",
    borderRadius: "8px",
    overflow: "hidden"
}));

export const PText = styledMUI("p", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            fontSize: "1.3rem",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});
export const H6Text = styledMUI("h6", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            marginBlockStart: "5%",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});

export const DivListAllItem = styledMUI("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
    paddingInline: "10px",
    flexDirection: "row",
    color: "black",
}));
function EditTextHorizontal(paramsComponent: InterfaceEditTextHorizontal) {
    const [getValue, setValue] = useState<string>(paramsComponent.value ?? "");
    useEffect(() => {
        setValue(paramsComponent.value ?? "")
    }, [paramsComponent.value])
    const mainTheme = useTheme();
    return (
        <DivEditTextWithTitle>
            <CardFromMUI style={{
                background: "linear-gradient(0deg, rgba(200, 200, 206, 1) 0%, rgba(235, 235, 235, 1) 100%)",
                borderEndEndRadius: "0px", borderStartEndRadius: "0px"
            }}>
                <DivListAllItem>
                    <PText isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</PText>
                </DivListAllItem>
            </CardFromMUI>
            <TextFieldFromMUI
                InputProps={paramsComponent.InputProps}
                size={paramsComponent.sizeWidth}
                isHintTextCenter={false}
                id={paramsComponent.setIdForElement ?? `outlined-basic${Math.random()}`}
                isValueMustLtr={paramsComponent.isDataMustLtr ?? false}
                label={paramsComponent.hintText}
                variant="outlined"
                type={paramsComponent.type}
                onChange={(e: any) => paramsComponent.onChangeValue(e.target.value)}
                value={getValue}
                multiline={paramsComponent.isMultiLine}
                disabled={paramsComponent.disable}
                style={{ ...paramsComponent.styledTextField, height: "100%" }}
                backgroundColor={paramsComponent.backGroundColor}
                isSmallHintShow={paramsComponent.isSmallHintShow} />
        </DivEditTextWithTitle>
    )
}

export default EditTextHorizontal;
